// Colors
$black: #000;
$white: #fff;
$whiteOpacity: #ffffffd9;
$color1: #330066;
$color2: #3EB7BA;
$color3: #FFCC00;
$color4: #F0F0F0;
$color5: #201851;
$color6: #F4F5F7;
$color7: #8898aa;
$color8: #424770;
$color9: #f6f9fc;
$color10: #DEDEDE;
$color11: #201851;
$color12: #231A5E;
$color13: #34A2A2;
$color14: #362063;
$color15: #9d1d1d;
$color16: #E67E22;
// Fonts
$mainFont: "co-headline";
$font-family-base: $mainFont;
$font-weight-base: 400;

// Transitions
$transition: all .3s ease;

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;