//  Cloudt Presentation Scss Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1.  Imports
//   2.  Font Imports
//   3.  Common Classes
//   4.  Header
//   5.  Footer
//   6.  Homepage
//   7.  Pages
//   8.  Features
//   9.  Subscriptions
//   10.  FAQ
//   11.  Login
//   12.  Contact
//   13.  Static
//   14.  Infographics
//   15. Redactor Custom Styles

// 1. Imports
// -------------------
@import '_variables';
@import "bootstrap";

// 2. Font Imports
// -------------------
@import url("https://use.typekit.net/nth3kzr.css");

// 3. Common Classes
// -------------------
// Responsive Font Size
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}
/* // Use Example
$min_width: 360px;
$max_width: 1920px;
$min_font: 18px;
$max_font: 55px;
@include fluid-type($min_width, $max_width, $min_font, $max_font); */

.co-headline {
    font-family: "co-headline",sans-serif;
}

b, strong {
    font-weight: bold;
}
.register-acc {
    color: $white;
    background: $color2;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.36);
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition;
    z-index: 2;

    span {
        font-weight: 400!important;
        font-size: 14px;
    }

    @include media-breakpoint-up(md) {
        width: 310px;
        height: 50px;
    }

    @include media-breakpoint-up(lg) {
        width: 375px;
        height: 60px;

        span {
            font-size: 18px;
        }

        &:hover {
            background: $color1;
            color: $white;
            text-decoration: none;
        }
    }
}

.secondaryButton {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    background: $color1;
    color: $white;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.36);
    width: 100%;
    height: 48px;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
        width: 310px;
        height: 50px;
    }

    @include media-breakpoint-up(lg) {
        width: unset;
        height: 60px;
        // padding: 0 30px;
    }

    &:hover {
        text-decoration: none;
        color: $white;

        @include media-breakpoint-up(lg) {
            background: $color2;
        }
    }

    span {
        font-weight: 400!important;
        font-size: 14px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }
}

.create-acc {
    margin: 36px 0;

    .register-acc {
        margin: auto;
    }

    @include media-breakpoint-up(lg) {
        margin: 105px 0;
    }
}

html,body {
    overflow-x: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bc1 {
    border-color: $color1!important;
}

.bc2 {
    border-color: $color2!important;
}

.bc3 {
    border-color: $color3!important;
}

.mobile-col-padding {
    padding-left: 28px;
    padding-right: 28px;

    @include media-breakpoint-up(md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.center-image {
    display: block;
    margin: auto;
}

.subscriptionsTable {
    background: $white;
    padding-top: 105px;
    padding-bottom: 150px;

    @include media-breakpoint-up(lg) {
        padding-top: 135px;
        padding-bottom: 300px;
    }

    &__title {
        position: relative;
        z-index: 2;
        color: $color1;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            margin-bottom: 60px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 32px;
            margin-bottom: 84px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 34px;
            margin-bottom: 114px;
        }

        strong {
            font-weight: 600;
        }
    }

    &__table {
        color: $color1;
        border: 1px solid #E2E2E2;
        background: $white;

        th,td {
            border-right: 1px solid #E2E2E2;
            vertical-align: middle;
        }

        thead, tbody {
            tr {
                th,td {
                    white-space: nowrap;

                    &:nth-child(2n) {
                        background: #F4F5F7;
                    }
                }
            }
        }

        thead {
            th {
                border-bottom: 1px solid #E2E2E2;
                font-weight: 400;
                font-size: 20px;

                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 28px;
                }
            }
        }

        tbody {
            td {
                font-weight: 400;
                font-size: 16px;

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 22px;
                }
            }

            .includedIcon {
                width: 32px;

                @include media-breakpoint-up(md) {
                    width: 37px;
                }

                @include media-breakpoint-up(lg) {
                    width: 47px;
                }
            }

            .excludedIcon {
                width: 20px;

                @include media-breakpoint-up(md) {
                    width: 24px;
                }

                @include media-breakpoint-up(lg) {
                    width: 31px;
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__topRightBox, &__bottomLeftBox {
        z-index: -1;
        position: absolute;
        max-width: 100%;
        height: auto;
        width: 330px;

        @include media-breakpoint-up(md) {
            width: 450px;
        }

        @include media-breakpoint-up(lg) {
            width: 570px;
        }

        @include media-breakpoint-up(xl) {
            width: 687px;
        }
    }

    &__topRightBox {
        top: -60px;
        right: -30px;

        @include media-breakpoint-up(md) {
            top: -90px;
            right: -40px;
        }

        @include media-breakpoint-up(lg) {
            top: -120px;
            right: -45px;
        }

        @include media-breakpoint-up(xl) {
            top: -165px;
            right: -80px;
        }
    }

    &__bottomLeftBox {
        bottom: -25px;
        left: -25px;

        @include media-breakpoint-up(md) {
            bottom: -40px;
            left: -45px;
        }

        @include media-breakpoint-up(lg) {
            bottom: -55px;
            left: -50px;
        }

        @include media-breakpoint-up(xl) {
            bottom: -69px;
            left: -74px;
        }
    }
}

.p404 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
}

.main-cta {
    &-button {
        display: inline-block;
        text-align: center;
        transition: $transition;
        background: $color16;
        border: 1px solid $color16;
        color: $white;
        font-size: 18px;
        padding: 15px 35px;

        &:hover {
            text-decoration: none;
            background: $color14;
            color: $white;
        }
    }

    span {
        margin-top: 10px;
        display: block;
        font-weight: 300;

        a {
            text-decoration: underline;
            color: $white;
        }
    }
}

// 4. Header
// -------------------
.header {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 16;
    transition: $transition;

    nav {
        width: 100%;
        padding: 0;
        height: 65px;

        a {
            color: $white;
        }

        #navbar__content {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                padding: 15px 35px 15px;
                height: 78px;
            }

            @include media-breakpoint-up(xl) {
                padding: 15px 5vw 15px;
            }
        }

        .mobile-menu__logo {
            position: absolute;
            top: 50%;
            left: calc(50% + 18px);
            transform: translate(-50%, -50%);
            transition: $transition;

            img {
              width: 55px;
              height: auto;
            }
        }

        .collapsed {
            .navbar-toggler {
                &__top-icon, &__mid-icon, &__bot-icon {
                    display: block;
                    width: 25px;
                    height: 3px;
                    transform: none;
                    transition: $transition;
                }

                &__top-icon {
                    background: $color3;
                }

                &__mid-icon {
                    background: $white;
                    opacity: 1;
                    margin: 7px 0 9px;
                }

                &__bot-icon {
                    background: $color5;
                }
            }
        }

        .navbar-toggler {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            &:focus {
                outline: none;
            }

            &__top-icon, &__bot-icon {
                display: block;
                position: relative;
                background: $color1;
                height: 3px;
                width: 25px;
                transition: $transition;
            }

            &__top-icon {
                transform: rotate(45deg);
            }

            &__bot-icon {
                transform: rotate(-45deg);
                top: -3px;
            }

            &__mid-icon {
                background: transparent;
                transition: $transition;
                opacity: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            height: unset;

            .mobile-menu__logo {
                display: none;
            }
        }
    }

    &.alternate-nav {
        background: $white;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

        a {
            color: $black;
        }

        .header__center--logo {
            &-alternate {
                opacity: 1;
                pointer-events: all;
            }
        }

        .navbar-toggler {
            &__mid-icon {
                background: $color2!important;
            }
        }
    }

    .invoicingButton {
        position: absolute;
        top: 0;
        right: 0;

        &:hover {
            .st0 {
                fill: $color1;
            }
        }

        svg {
            width: auto;
            height: 78px;
            padding: 0;

            .st0 {
                transition: $transition;
                fill: $color13;
            }
            .st1 {
                fill: $white;
            }
        }
    }

    &__content {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-template-rows: 1fr;
        align-items: center;
    }

    &__center {
        a {
            display: inline-block;
            position: relative;
            transition: $transition;
            vertical-align: middle;
            line-height: 48px;
            @include fluid-type(992px, 1920px, 12px, 18px);
            padding: 0 1.2vw;

            @include media-breakpoint-up(xl) {
                padding: 0 1.3vw;
            }

            &:hover {
                text-decoration: none;

                &:after {
                    background: $color2;
                }
            }

            &:after {
                transition: $transition;
                content: "";
                position: absolute;
                bottom: -15px;
                left: 1.3vw;
                width: calc(100% - 2.6vw);
                height: 5px;
                background: transparent;
            }

            &.active {
                &:after {
                    background: $color2;
                }
            }
        }
    }

    &__left {
        margin-right: 15px;

        @include media-breakpoint-up(lg) {
            margin-right: 20px;
        }

        a {
            display: inline-block;
            margin-bottom: 3px;

            &:hover {
                text-decoration: none;
            }

            &:not(:last-of-type) {
                margin-right: 50px;

                @include media-breakpoint-up(xl) {
                    margin-right: 60px;
                }
            }
        }

        img {
            min-width: 28px;
            max-width: 43px;
            width: 2vw;
            margin-right: 8px;
        }

        svg {
            min-width: 100px;
            max-width: 167px;
            width: 8vw;
        }
    }

    &__right {
        text-align: right;

        a {
            transition: $transition;
            @include fluid-type(992px, 1920px, 12px, 18px);

            &:hover {
                text-decoration: none;
            }

            &.headerButton {
                background: $color1;
                color: $white!important;
                border-radius: 34px;
                padding: 12px 20px 10px;
                margin-right: 15px;

                @include media-breakpoint-up(xl) {
                    padding: 14px 25px 12px;
                    margin-right: 20px;
                }

                &:hover {
                    background: $color2;
                }

                &.alternate {
                    background: $color2;
                    margin-right: 30px;

                    @include media-breakpoint-up(xl) {
                        margin-right: 45px;
                    }

                    &:hover {
                        background: $color1;
                    }
                }

                &.alternate2 {
                    background: $color16;
                    // margin-right: 15px;

                    @include media-breakpoint-up(xl) {
                        // margin-right: 30px;
                    }

                    &:hover {
                        background: $color2;
                    }
                }
            }

            &.loginButton {
                display: inline-block;
                position: relative;
                margin-right: 15px;

                @include media-breakpoint-up(xl) {
                    margin-right: 20px;
                }

                &:hover {
                    text-decoration: none;

                    &:after {
                        background: $color2;
                    }
                }

                &:after {
                    transition: $transition;
                    content: "";
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    background: transparent;
                }
            }
        }
    }

    a.cloudtInvoicing, a.cloudtAccounting {
        transition: $transition;
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        border-bottom: 1px solid #000;

        span {
            color: $color2;
        }

        &:hover {
            text-decoration: none;
            background: $black;
            color: $white;
        }
    }

    a.cloudtInvoicing {
        background: $color4;
        color: $color1;
    }

    a.cloudtAccounting {
        background: $color11;
        color: $white;
    }

    .mobile-menu {
        &__secondary {
            &--item {
                &.registerButton, &.proButton {
                    border-radius: 35px;
                    border: 2px solid $color1;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // width: 210px;
                    height: 50px;
                    margin: 6px 0;
                }

                &.registerButton {
                    background: $color1;
                    color: $white!important;

                    &.alternate {
                        background: $color13;
                        border-color: $color13;
                    }

                    &.alternate2 {
                        background: $color16;
                        border-color: $color16;
                    }
                }

                &.proButton {
                    color: $color1!important;
                }
            }
        }
    }
}

.mobile-menu {
    position: absolute;
    left: 10px;
    top: 65px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    perspective: 2000px;

    &__container {
        background: $white;
        box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        font-size: 17px;
        line-height: 40px;
        white-space: nowrap;
        flex-grow: 1;
        transform: scale(.95);
        transform-origin: 100% 0;
        opacity: 0;
        will-change: transform,opacity;
        transition-property: transform,opacity;
        transition-duration: .25s;

        a:hover {
            text-decoration: none;
        }
    }

    &__features {
        padding: 20px 0 15px;

        h4 {
            font-size: 17px;
            letter-spacing: .025em;
            color: $color8;
            text-transform: uppercase;
            margin: 0 0 5px 12px;
        }

        &--list {
            >ul {
                padding: 0;
                margin: 0;
                list-style: none;
                column-count: 2;

                li {
                    list-style: none;
                }
            }

            &-item {
                color: #32325d!important;
                display: block;
                padding: 0 12px;
                font-size: 16px;
                font-weight: 500;
                line-height: 42px;
                outline: none;
                position: relative;
                text-decoration: none;
                -webkit-tap-highlight-color: transparent;

                img,svg {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    margin: 0 2px 0 0;
                    vertical-align: -6px;
                }
            }
        }
    }

    &__secondary {
        border-top: 2px solid $color9;
        padding: 15px 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;

        >ul {
            flex-grow: 1;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                list-style: none;
            }
        }

        &--item {
            padding: 0 4px;
            min-width: 100px;
            display: block;
            text-decoration: none;
            -webkit-tap-highlight-color: transparent;
            color: $color8!important;
        }
    }

    &__sign-in {
        display: block;
        background: $color9;
        padding: 12px 14px;
        font-weight: 600;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        color: $color8!important;

        &:after {
            font: normal 16px StripeIcons;
            content: "\279C";
            margin-left: 6px;
        }
    }
}

.navbar-collapse {
    &.show {
        .mobile-menu {
            &__container {
                transform: none;
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    &.collapsing {
        transition: none;
    }
}

// 5. Footer
// -------------------
.footer {
    background: $color5;
    padding-top: 42px;
    padding-bottom: 30px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
        padding-bottom: 100px;
    }

    &__links {
        color: $white;
        padding-top: 40px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &--title {
            color: inherit;
            margin-bottom: 28px;
            display: inline-block;
        }

        &--item {
            a {
                color: inherit;
                font-weight: 300;
                text-transform: uppercase;
            }
        }
    }

    &__app {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        @include media-breakpoint-up(md) {
            align-items: flex-start;
            margin-top: 35px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }

        a {
            display: inline-block;

            &:first-of-type {
                margin-bottom: 25px;
            }
        }

        img {
            width: 120px;

            @include media-breakpoint-up(md) {
                width: 120px;
            }

            @include media-breakpoint-up(lg) {
                width: 135px;
            }
        }
    }
}

// 6. Homepage
// -------------------
.home {
    &__description {
        background: $color4;
        z-index: 2;

        &--left, &--right {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: $color2;
            }
        }

        &--right {
            background: url('/img/common/cloudt-bg.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 100px;
            padding-bottom: 30px;
            flex-direction: column;

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    position: relative;
                    padding-left: 55px;
                    margin-bottom: 10px;

                    &:before {
                        content: "";
                        background-image: url('/img/common/check1.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        width: 47px;
                        height: 47px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:nth-of-type(2) {
                        &:before {
                            background-image: url('/img/common/check2.svg');
                        }
                    }

                    &:nth-of-type(3) {
                        &:before {
                            background-image: url('/img/common/check3.svg');
                        }
                    }
                }
            }

            @include media-breakpoint-up(md) {
                background-size: unset;
                padding-top: 120px;
                padding-bottom: 85px;
            }

            .home__app {
                a {
                    display: inline-block;

                    &:first-of-type {
                        margin-right: 6px;
                    }
                }

                img {
                    width: 100px;

                    @include media-breakpoint-up(md) {
                        width: 120px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 146px;
                    }
                }
            }
        }

        &--left {
            padding-bottom: 30px;

            @include media-breakpoint-up(md) {
                padding-top: 120px;
                padding-bottom: 85px;
            }
        }
    }

    .home__tabs {
        margin-top: 0;

        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }

        .col {
            padding-left: 0;
            padding-right: 0;

            @include media-breakpoint-up(md) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .nav {
            margin-bottom: 80px;
            padding: 0;
            background: none;
            justify-content: space-between;
            max-width: 100%;

            @include media-breakpoint-up(xl) {
                padding: 0 8px;
            }

            &-item {
                width: unset;
            }

            &-link {
                display: flex;
                justify-content: flex-end;
                height: 100%;
                padding: 0;
                font-size: 13px;

                @include media-breakpoint-up(xl) {
                    font-size: 16px;
                }

                span {
                    margin-top: 8px;
                    margin-left: 0;
                    text-transform: uppercase;
                }

                &.active {
                    background: transparent;
                    border-color: transparent;
                }
            }
        }

        &#mobileFeatures {
            background-image: url('/img/homepage/cloud-gray.svg');
            background-repeat: no-repeat;
            background-size: 340px;
            background-position: -30px 100px;
            padding-bottom: 60px;

            @include media-breakpoint-up(md) {
                background: none;
                padding-bottom: 80px;
            }

            .flickity-viewport {
                width: 100%;
                overflow: visible;
            }

            .home__tabs {
                &--item {
                    width: 100%;

                    .infotab__container {
                        @include media-breakpoint-up(md) {
                            width: 672px;
                            height: 460px;
                        }
                    }
                }

                &--arrows {
                    width: 100%;
                    position: absolute;
                    top: 31px;
                    left: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    z-index: 1;
                    padding: 0 1.8%;

                    img {
                        width: 71px;
                        padding: 13px 0;

                        &:last-of-type {
                            transform: rotate(180deg);
                        }
                    }
                }

                &--bg {
                    @include media-breakpoint-up(md) {
                        width: 672px;
                        height: 460px;
                    }
                }
            }

            .infotab__heading {
                height: 80px;
                background: $color4;
                transition: $transition;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                padding-bottom: 14px;

                svg {
                    transition: $transition;
                }

                span {
                    text-transform: uppercase;
                    color: $color1;
                    margin-top: 6px;
                    font-size: 18px;
                    line-height: 1.2;
                }
            }
        }
    }

    &__tabs {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 90px;
        }

        &--heading {
            display: flex;
            flex-wrap: nowrap;

            &-left {
                background: $color1;
                width: 25px;
                flex-grow: 1;

                @include media-breakpoint-up(md) {
                    width: 80px;
                }
            }

            &-right {
                background: $color3;
                width: 25px;
                flex-grow: 1;

                @include media-breakpoint-up(md) {
                    width: 80px;
                }
            }

            &-center {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $color2;
                color: $white;
                height: 80px;
                font-size: 18px;
                padding: 5px 8px;
                text-align: center;

                @include media-breakpoint-up(md) {
                    padding: 5px 15px;
                    font-size: 20px;
                    flex-grow: 1;
                }

                @include media-breakpoint-up(lg) {
                    padding: 5px;
                    width: 875px;
                    flex-grow: 0;
                }
            }
        }

        .infotab {
            &__container {
                width: 100%;
                height: 100%;
            }

            &__text--alternate {
                span {font-weight: 300;}
            }
        }

        .nav {
            max-width: 874px;
            margin: auto;
            margin-bottom: 50px;
            border: none;
            flex-direction: column;
            justify-content: center;

            &-item {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 32%;
                    bottom: unset;

                    span {
                        vertical-align: text-top;
                    }
                }

                @include media-breakpoint-up(lg) {
                    width: 30%;
                }

                &.is-selected {
                    .nav-link {
                        color: $color2;

                        .dashboard,.payroll,.invoices,.payments {
                            .a,.b{stroke:$color2;}
                        }

                        .books,.taxes,.company_documents,.hire,.messages {
                            .a{stroke:$color2;}
                        }
                    }
                }
            }

            &-link {
                border: none;
                color: $color1;
                transition: $transition;
                font-size: 18px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                svg {
                    transition: $transition;
                }

                @include media-breakpoint-up(md) {
                    font-size: 16px;
                    display: block;

                    span {
                        margin-left: 4px;
                    }

                    &.active {
                        color: $color2;

                        span {
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                bottom: -2px;
                                left: 0;
                                right: 0;
                                height: 2px;
                                width: 100%;
                                background: $color2;
                            }
                        }

                        .dashboard,.payroll,.invoices,.payments {
                            [class$="a"],[class$="b"]{stroke:$color2;}
                        }

                        .books,.taxes,.company_documents,.hire,.messages {
                            [class$="a"]{stroke:$color2;}
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    font-size: 18px;

                    &:hover {
                        color: $color2;

                        .dashboard,.payroll,.invoices,.payments {
                            [class$="a"],[class$="b"]{stroke:$color2;}
                        }

                        .books,.taxes,.company_documents,.hire,.messages {
                            [class$="a"]{stroke:$color2;}
                        }
                    }
                }
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }

        .tab-content {
            position: relative;
            margin: auto;
            width: 308px;
            height: 406px;

            &:after {
                content: "";
                position: absolute;
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                width: calc(100% + 96px);
                height: 100%;
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                @include media-breakpoint-up(md) {
                    z-index: -1;
                }
            }

            &:before {
                content: "";
                position: absolute;
                border-left: 2px solid $color2;
                border-right: 2px solid $color2;
                height: calc(100% + 25px);
                width: 100%;
                left: 0;
                top: 0;

                @include media-breakpoint-up(md) {
                    z-index: -1;
                }
            }

            @include media-breakpoint-up(md) {
                width: 672px;
                height: 460px;

                &:before {
                    height: calc(100% + 60px);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            @include media-breakpoint-up(lg) {
                width: 874px;
            }

            &--wrapper {
                max-width: 1000px;
                margin: auto;
            }
        }

        .tab-pane {
            width: 100%;
            height: 100%;

            a {
                display: block;
            }

            img {
                display: block;
                margin: auto;
            }
        }

        .show-more {
            background: $color1;
            color: $white;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.36);
            height: 44px;
            width: 228px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition;
            margin: auto;

            &:hover {
                text-decoration: none;
                background: $color2;
            }
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 50%;
            margin: auto;
            width: 308px;
            height: 406px;
            transform: translateX(-50%);
            margin-top: 80px;

            &:after {
                content: "";
                position: absolute;
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                width: calc(100% + 96px);
                height: 100%;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:before {
                content: "";
                position: absolute;
                border-left: 2px solid $color2;
                border-right: 2px solid $color2;
                height: calc(100% + 25px);
                width: 100%;
                left: 0;
                top: 0;
            }
        }

        &--item {
            .infotab__container {
                position: relative;
                margin: auto;
                width: 308px;
                height: 406px;
            }
        }

        #tab1Content, #tab2Content, #tab3Content, #tab4Content ,#tab5Content, #tab6Content, #tab7Content, #tab8Content, #tab9Content, #tab10Content, #tab11Content, #tab12Content{
            .infotab {
                &__screenshot {
                    position: absolute;
                    bottom: 47px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;

                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                        left: 20px;
                        transform: none;
                    }

                    @include media-breakpoint-up(lg) {
                        top: -35px;
                        right: -30px;
                        bottom: unset;
                        left: unset;
                    }

                    &--img {
                        width: 260px;
                        height: auto;

                        @include media-breakpoint-up(md) {
                            width: 420px;
                        }
                    }

                    &--link {
                        color: $white;
                        background: $color1;
                        width: 228px;
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        margin-top: 10px;
                        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.36);
                        transition: $transition;

                        position: absolute;
                        bottom: -21px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 4;

                        @include media-breakpoint-up(md) {
                            position: static;
                            transform: none;
                        }

                        @include media-breakpoint-up(lg) {
                            &:hover {
                                color: $white;
                                background: $color2;
                                text-decoration: none;
                            }
                        }
                    }
                }

                &__text {
                    z-index: 1;

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        left: 80px;
                        top: -15px;
                    }

                    @include media-breakpoint-up(lg) {
                        top: -35px;
                        left: -70px;
                    }

                    &--bg {
                        position: relative;
                        width: 428px;
                        height: auto;
                    }

                    span {
                        line-height: 1;
                        color: $color5;
                        font-size: 14px;
                        display: block;
                        text-align: center;
                        padding: 28px 32px 0;
                        font-weight: 300;

                        @include media-breakpoint-up(md) {
                            line-height: 1.2;
                            padding: 0;
                            width: 340px;
                            position: absolute;
                            top: calc(50% + 12px);
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 17px;
                            color: $white;
                        }
                    }
                }

                &__figure {
                    z-index: 2;
                    position: absolute;
                    bottom: 21px;
                    left: 190px;
                    width: 114px;
                    height: auto;

                    @include media-breakpoint-up(md) {
                        left: unset;
                        bottom: 33px;
                        right: 13px;
                        width: 154px;
                    }

                    @include media-breakpoint-up(lg) {
                        top: 150px;
                        left: 270px;
                        bottom: unset;
                        right: unset;
                    }
                }
            }
        }

        #tab1Content {
            .infotab {
                &__figures {
                    position: absolute;
                    display: flex;
                    bottom: -2px;
                    right: 5px;
                    z-index: 3;

                    @include media-breakpoint-up(md) {
                        bottom: 18px;
                        right: 20px;
                    }

                    @include media-breakpoint-up(lg) {
                        bottom: 18px;
                        right: 394px;
                    }

                    &--icon {
                        position: absolute;
                        bottom: 32px;
                        right: 65px;
                        z-index: -1;
                        max-width: unset;
                        width: 70px;

                        @include media-breakpoint-up(md) {
                            bottom: 10px;
                            right: 100px;
                            width: 140px;
                        }
                    }

                    &--figure {
                        width: 80px;
                        height: auto;
                    }

                    #infotab__figures--figure {
                        .a {
                            fill:#e0e1e3;
                        }
                        .b {
                            fill:#201851;
                        }
                        .c {
                            fill:#ffc000;
                        }
                        .d {
                            fill:#e0e1e1;
                        }
                        .e {
                            fill:#3eb7ba;
                        }

                        width: 80px;
                        height: auto;

                        @include media-breakpoint-up(md) {
                            width: 118px;
                        }
                    }
                }

                &__text {
                    @include media-breakpoint-up(md) {
                        bottom: unset;
                        top: 40px;
                        left: 320px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: unset;
                        top: 26px;
                        left: 180px;
                    }

                    &--bg {
                        width: 281px;
                    }

                    span {
                        padding: 12px 20px 0;

                        @include media-breakpoint-up(md) {
                            padding: 0 20px;
                            width: 226px;
                            top: calc(50% + 4px);
                        }
                    }

                    &--alternate {
                        width: 165px;
                        height: 135px;
                        box-shadow: -5px 5px 0 0 $color1;
                        border: 1px solid $color2;
                        position: absolute;
                        background: $white;
                        line-height: 1.2;
                        z-index: 2;
                        top: 65px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include media-breakpoint-up(md) {
                            width: 256px;
                            height: 244px;
                            box-shadow: -16px 16px 0 0 $color1;
                            top: 10px;
                            left: 30px;
                            transform: none;
                        }

                        @include media-breakpoint-up(lg) {
                            top: 141px;
                            left: -45px;
                        }

                        span {
                            color: $color1;
                            text-align: center;
                            padding: 0 10px;
                            font-size: 12px;

                            @include media-breakpoint-up(md) {
                                padding: 0 18px;
                                font-size: 17px;
                            }
                        }
                    }
                }

                &__screenshot {
                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        left: unset;
                        bottom: unset;
                        top: 70px;
                        right: -70px;
                    }

                    &--img {
                        // border: 1px solid $color1;
                        // box-shadow: 7px 7px 0 0 $color2;

                        @include media-breakpoint-up(md) {
                            width: 360px;
                            // box-shadow: 13px 13px 0 0 $color2;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 433px;
                        }
                    }

                    &--link {
                        margin-top: 23px;

                        @include media-breakpoint-up(md) {
                            margin-top: 25px;
                        }

                        @include media-breakpoint-up(lg) {
                            margin-top: 35px;
                        }
                    }
                }
            }
        }

        #tab2Content {
            .infotab {
                &__figures {
                    position: absolute;
                    display: flex;
                    justify-content: space-between;
                    bottom: 6px;
                    right: 7px;
                    z-index: 2;

                    @include media-breakpoint-up(md) {
                        width: 245px;
                        right: 8px;
                        bottom: -20px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 335px;
                        right: 82px;
                        bottom: -20px;
                    }

                    img {
                        margin: unset;
                    }

                    &--checks {
                        align-self: flex-start;
                    }

                    #infotab__figures--checks {
                        overflow: visible;
                        align-self: flex-start;

                        .a {
                            fill:none;
                        }
                        .b {
                            clip-path:url(#a);
                        }
                        .c {
                            fill:#fc0;
                        }
                        .d {
                            fill:#3eb7ba;
                        }
                        .e {
                            fill:#306;
                        }

                        @include media-breakpoint-up(md) {
                            width: 70px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 94px;
                        }
                    }

                    &--lf {
                        width: 50px;

                        @include media-breakpoint-up(md) {
                            width: 70px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 94px;
                        }
                    }

                    &--rf {
                        width: 60px;

                        @include media-breakpoint-up(md) {
                            width: 85px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 109px;
                        }
                    }
                }

                &__text {
                    @include media-breakpoint-up(lg) {
                        left: unset;
                        right: -30px;
                        top: -40px;
                    }

                    &--bg {
                        @include media-breakpoint-up(lg) {
                            width: 405px;
                        }
                    }
                }

                &__screenshot {
                    bottom: 85px;

                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: unset;
                        left: -50px;
                        top: 88px;
                    }

                    &--img {
                        // border: 1px solid $color1;
                        // box-shadow: 7px 7px 0 0 $color2;

                        @include media-breakpoint-up(md) {
                            width: 370px;
                            // box-shadow: 10px 10px 0 0 $color2;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 433px;
                            // box-shadow: 13px 13px 0 0 $color2;
                        }
                    }

                    &--link {
                        margin-top: 25px;

                        @include media-breakpoint-up(lg) {
                            margin-top: 35px;
                        }
                    }
                }
            }
        }

        #tab3Content {
            .infotab {
                &__screenshot {
                    z-index: 2;
                    bottom: 75px;

                    @include media-breakpoint-up(md) {
                        left: unset;
                        top: unset;
                        right: 10px;
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: 80px;
                        bottom: 37px;
                    }

                    &--link {
                        margin-top: 25px;

                        @include media-breakpoint-up(md) {
                            margin-top: 10px;
                        }
                    }
                }

                &__text {
                    @include media-breakpoint-up(md) {
                        left: 60px;
                    }

                    @include media-breakpoint-up(lg) {
                        left: 100px;
                    }

                    &--bg {
                        @include media-breakpoint-up(lg) {
                            width: 415px;
                        }
                    }

                    span {
                        @include media-breakpoint-up(lg) {
                            width: 350px;
                        }
                    }
                }

                &__figures {
                    display: flex;
                    position: absolute;
                    z-index: 2;
                    bottom: 0px;
                    right: 5px;

                    @include media-breakpoint-up(md) {
                        right: unset;
                        bottom: 10px;
                        left: 80px;
                    }

                    @include media-breakpoint-up(lg) {
                        bottom: unset;
                        left: 115px;
                        top: 145px;
                    }

                    &--figure {
                        height: auto;
                        width: 90px;

                        @include media-breakpoint-up(md) {
                            width: 126px;
                        }
                    }

                    &--list {
                        position: absolute;
                        left: 60px;
                        bottom: 60px;
                        width: 45px;
                    }

                    #infotab__figures--list {
                        position: absolute;
                        left: 60px;
                        bottom: 60px;
                        width: 45px;

                        .a{fill:#3eb7ba;}.b{fill:#ffc000;}.c{fill:#201851;}

                        @include media-breakpoint-up(md) {
                            left: 85px;
                            bottom: 75px;
                            width: 78px;
                        }
                    }
                }
            }
        }

        #tab4Content {
            .infotab {
                &__screenshot {
                    @include media-breakpoint-up(lg) {
                        top: 120px;
                        left: 5px;
                        right: unset;
                        bottom: unset;
                    }
                }

                &__figure {
                    width: 60px;
                    top: unset;
                    left: unset;
                    right: 25px;
                    bottom: 31px;

                    @include media-breakpoint-up(md) {
                        width: 70px;
                        top: unset;
                        left: unset;
                        right: 235px;
                        bottom: 0px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 87px;
                        bottom: 21px;
                        right: 302px;
                    }
                }

                &__grid {
                    display: none;
                }

                &__text {
                    @include media-breakpoint-up(md) {
                        position: static;
                    }

                    span {
                        color: $color1;
                        position: static;
                        transform: none;
                        width: unset;
                        padding: 14px;

                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                        }
                    }

                    &--alternate {
                        background: $white;
                        border: 1px solid $color2;
                        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 10px;
                        width: 165px;
                        height: 135px;
                        position: absolute;
                        top: 56px;
                        left: 50%;
                        transform: translateX(-50%);

                        @include media-breakpoint-up(md) {
                            width: 200px;
                            height: 200px;
                            padding: 0 10px;
                            left: unset;
                            transform: none;
                            right: 40px;
                            top: 65px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 243px;
                            height: 243px;
                            padding: 0 30px;
                            right: 102px;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        span {
                            line-height: 1.2;
                            text-align: center;
                            color: $color1;
                            font-size: 12px;

                            @include media-breakpoint-up(md) {
                                font-size: 17px;
                            }
                        }

                        img {
                            position: absolute;
                            top: -10px;
                            right: -15px;
                            width: 40px;
                            height: auto;

                            @include media-breakpoint-up(md) {
                                top: 0px;
                                right: -15px;
                                width: 50px;
                            }

                            @include media-breakpoint-up(lg) {
                                top: 8px;
                                right: 28px;
                            }
                        }
                    }
                }
            }

            #infotab__grid {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    position: absolute;
                    z-index: -1;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 610px;
                }

                @include media-breakpoint-up(lg) {
                    width: 785px;
                }
            }
        }

        #tab5Content {
            .infotab {
                &__screenshot {
                    bottom: 100px;

                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                        left: unset;
                        right: 40px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: unset;
                        bottom: unset;
                        left: -80px;
                        top: 60px;
                    }

                    &--img {
                        // border: 1px solid $color1;
                        // box-shadow: 7px 7px 0 0 $color2;

                        @include media-breakpoint-up(md) {
                            width: 400px;
                            // box-shadow: 10px 10px 0 0 $color2;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 433px;
                            // box-shadow: 13px 13px 0 0 $color2;
                        }
                    }

                    &--link {
                        margin-top: 25px;

                        @include media-breakpoint-up(lg) {
                            margin-top: 35px;
                        }
                    }
                }

                &__text {
                    @include media-breakpoint-up(md) {
                        left: 120px;
                    }

                    @include media-breakpoint-up(lg) {
                        left: unset;
                        right: -45px;
                    }

                    span {
                        width: 320px;
                        line-height: 1.1;

                        @include media-breakpoint-up(md) {
                            line-height: 1.2;
                        }
                    }
                }

                &__figure {
                    width: 105px;
                    left: unset;
                    top: unset;
                    bottom: -9px;
                    right: 4px;

                    @include media-breakpoint-up(md) {
                        width: 133px;
                        right: unset;
                        left: 30px;
                        bottom: 33px;
                    }

                    @include media-breakpoint-up(lg) {
                        left: unset;
                        top: unset;
                        right: 325px;
                        bottom: 38px;
                    }
                }
            }
        }

        #tab6Content {
            .infotab {
                &__figure {
                    width: 170px;
                    left: unset;
                    right: -15px;
                    bottom: -4px;

                    @include media-breakpoint-up(md) {
                        width: 220px;
                        right: 13px;
                        bottom: 33px;
                    }
                }

                &__text {
                    @include media-breakpoint-up(md) {
                        left: 30px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 362px;
                        top: unset;
                        left: unset;
                        right: -46px;
                        bottom: 32px;
                    }

                    span {
                        line-height: 1.2;
                    }
                }

                &__screenshot {
                    bottom: 100px;

                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: unset;
                        top: 30px;
                        left: 222px;
                        width: 425px;
                    }

                    &--link {
                        margin-top: 25px;

                        @include media-breakpoint-up(md) {
                            margin-top: 10px;
                        }
                    }
                }
            }

            #infotab6__figure {
                position: absolute;
                width: 170px;
                left: unset;
                right: 10px;

                .a{fill:#3eb7ba;}.b{fill:#e0e1e3;}.c{fill:#ffc000;}.d{fill:#e0e1e1;}.e{fill:#201851;}

                @include media-breakpoint-up(md) {
                    width: 220px;
                    right: 15px;
                    bottom: 33px;
                }

                @include media-breakpoint-up(lg) {
                    top: unset;
                    right: unset;
                    left: -35px;
                    bottom: 25px;
                    width: 255px;
                }
            }
        }

        #tab7Content {
            .infotab {
                &__screenshot {
                    bottom: 100px;

                    @include media-breakpoint-up(md) {
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        bottom: unset;
                    }
                }
            }

            #infotab7__figure {
                z-index: 2;
                position: absolute;
                bottom: 0px;
                left: 190px;
                width: 114px;
                height: auto;

                @include media-breakpoint-up(md) {
                    left: unset;
                    bottom: 33px;
                    right: 13px;
                    width: 154px;
                }

                @include media-breakpoint-up(lg) {
                    top: 150px;
                    left: 270px;
                    bottom: unset;
                    right: unset;
                }

                .a{fill:#e0e1e1;}.b,.f{fill:#201851;}.c{fill:#3eb7ba;}.d{fill:#ffc000;}.e{fill:#e0e1e3;}.f{stroke:#201851;stroke-miterlimit:10;stroke-width:2px;}
            }
        }

        #tab8Content {
            .infotab {
                &__figure {
                    width: 70px;
                    left: 215px;
                    bottom: 13px;

                    @include media-breakpoint-up(md) {
                        width: 92px;
                        right: unset;
                        left: 40px;
                        bottom: 33px;
                    }

                    @include media-breakpoint-up(lg) {
                        left: 50px;
                        bottom: 53px;
                        top: unset;
                    }
                }

                &__text {
                    left: 135px;

                    &--bg {
                        @include media-breakpoint-up(md) {
                            width: 420px;
                        }
                    }

                    span {
                        width: 320px;
                    }
                }

                &__screenshot {
                    bottom: 80px;

                    @include media-breakpoint-up(md) {
                        left: unset;
                        right: 20px;
                        bottom: -78px;
                    }

                    @include media-breakpoint-up(lg) {
                        top: unset;
                        bottom: 36px;
                        right: 0;
                        z-index: 2;
                    }

                    &--link {
                        margin-top: 25px;

                        @include media-breakpoint-up(md) {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        #tab9Content {
            .infotab{
                &__text {
                    position: static;

                    span {
                        position: static;
                        color: $color1;
                        transform: none;
                        width: unset;
                        padding: 14px 20px 0;

                        @include media-breakpoint-up(md) {
                            padding: 24px 24px 0;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 14px 24px 0;
                        }
                    }
                }

                &__screenshot {
                    bottom: 29px;

                    @include media-breakpoint-up(md) {
                        left: -20px;
                        bottom: -65px;

                        img {
                            width: 350px;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        top: unset;
                        right: unset;
                        left: -105px;
                        bottom: 44px;

                        img {
                            width: 420px;
                        }
                    }
                }

                &__figure {
                    bottom: 70px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 95%;
                    z-index: -1;

                    @include media-breakpoint-up(md) {
                        transform: none;
                        left: unset;
                        top: unset;
                        width: 385px;
                        bottom: 10px;
                        z-index: 2;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 518px;
                        bottom: 14px;
                        right: 35px;
                    }
                }
            }

            #infotab9__figure {
                overflow: visible;
                position: absolute;
                height: auto;

                .a,.b,.c{fill:none;stroke-width:2px;}.a{stroke:#3eb7ba;}.b{stroke:#306;}.c{stroke:#fc0;}.d{fill:#e0e1e1;}.e{fill:#ffc000;}.f{fill:#201851;}.g{fill:#3eb7ba;}.h{fill:#e0e1e3;}.i{fill:#fc0;}

                @include media-breakpoint-up(md) {
                    width: 385px;
                    bottom: 10px;
                    right: 13px;
                }

                @include media-breakpoint-up(lg) {
                    width: 518px;
                    bottom: 14px;
                    right: 35px;
                }
            }
        }

        #tab10Content {
            .infotab {
                &__text {
                    position: static;

                    span {
                        position: static;
                        color: $color1;
                        transform: none;
                        width: unset;
                        padding: 14px 20px 0;

                        @include media-breakpoint-up(md) {
                            padding: 24px 24px 0;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 14px 24px 0;
                        }
                    }
                }

                &__screenshot {
                    bottom: 90px;

                    @include media-breakpoint-up(md) {
                        left: 80px;
                        bottom: 30px;

                        img {
                            width: 350px;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        top: unset;
                        right: unset;
                        left: 120px;
                        bottom: 44px;

                        img {
                            width: 420px;
                        }
                    }
                }

                &__figure {
                    bottom: 30px;
                    left: unset;
                    right: 15px;
                    width: 85px;

                    @include media-breakpoint-up(md) {
                        z-index: 2;
                        width: 100px;
                        top: unset;
                        bottom: 20px;
                        right: unset;
                        left: 450px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 120px;
                        bottom: 22px;
                        left: 600px;
                    }
                }
            }
        }

        #tab11Content {
            .infotab {
                &__text {
                    position: static;

                    span {
                        position: static;
                        color: $color1;
                        transform: none;
                        width: unset;
                        padding: 14px 20px 0;

                        @include media-breakpoint-up(md) {
                            padding: 24px 24px 0;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 14px 24px 0;
                        }
                    }
                }

                &__screenshot {
                    bottom: 90px;

                    @include media-breakpoint-up(md) {
                        left: unset;
                        right: 95px;
                        bottom: 30px;

                        img {
                            width: 350px;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        top: unset;
                        right: 110px;
                        bottom: 44px;

                        img {
                            width: 420px;
                        }
                    }
                }

                &__figure {
                    bottom: 30px;
                    left: unset;
                    right: 15px;
                    width: 70px;

                    @include media-breakpoint-up(md) {
                        z-index: 2;
                        width: 80px;
                        top: unset;
                        bottom: 20px;
                        left: unset;
                        right: 470px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 90px;
                        bottom: 44px;
                        right: 600px;
                    }
                }
            }
        }

        #tab12Content {
            .infotab{
                &__text {
                    position: static;

                    span {
                        position: static;
                        color: $color1;
                        transform: none;
                        width: unset;
                        padding: 14px 20px 0;

                        @include media-breakpoint-up(md) {
                            padding: 24px 24px 0;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 14px 24px 0;
                        }
                    }
                }

                &__screenshot {
                    bottom: 29px;

                    @include media-breakpoint-up(md) {
                        left: -20px;
                        bottom: -65px;

                        img {
                            width: 350px;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        top: unset;
                        right: unset;
                        left: -105px;
                        bottom: 44px;

                        img {
                            width: 420px;
                        }
                    }
                }

                &__figure {
                    bottom: 70px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 95%;
                    z-index: -1;

                    @include media-breakpoint-up(md) {
                        transform: none;
                        left: unset;
                        top: unset;
                        width: 385px;
                        bottom: 10px;
                        z-index: 2;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 518px;
                        bottom: 14px;
                        right: 35px;
                    }
                }
            }

            #infotab12__figure {
                overflow: visible;
                position: absolute;
                height: auto;

                .a,.b,.c{fill:none;stroke-width:2px;}.a{stroke:#3eb7ba;}.b{stroke:#306;}.c{stroke:#fc0;}.d{fill:#e0e1e1;}.e{fill:#ffc000;}.f{fill:#201851;}.g{fill:#3eb7ba;}.h{fill:#e0e1e3;}.i{fill:#fc0;}

                @include media-breakpoint-up(md) {
                    width: 385px;
                    bottom: 10px;
                    right: 13px;
                }

                @include media-breakpoint-up(lg) {
                    width: 518px;
                    bottom: 14px;
                    right: 35px;
                }
            }
        }
    }

    &__buttons {
        margin-top: 45px;

        @include media-breakpoint-up(md) {
            margin-top: 110px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-bottom: 70px;
        }

        &--item {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 100%;
            border: 2px solid;
            margin-bottom: 20px;

            &.bc1 {
                img {
                    width: 105px;

                    @include media-breakpoint-up(md) {
                        width: 78px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 105px;
                    }
                }
            }

            &.bc2 {
                img {
                    width: 95px;

                    @include media-breakpoint-up(md) {
                        width: 70px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 95px;
                    }
                }
            }

            &.bc3 {
                img {
                    width: 132px;

                    @include media-breakpoint-up(md) {
                        width: 98px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 132px;
                    }
                }
            }

            &-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    white-space: nowrap;
                    color: $black;
                    margin-top: 8px;
                    font-size: 16px;

                    @include media-breakpoint-up(md) {
                        font-size: 15px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                }

                @include media-breakpoint-up(md) {
                    width: 100%;
                    height: 100%;
                    justify-content: flex-end;
                    padding-bottom: 46px;
                }

                @include media-breakpoint-up(lg) {
                    padding-bottom: 110px;
                }
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    &__banner {
        margin: 45px 0;

        @include media-breakpoint-up(lg) {
            margin: 90px 0;
        }

        &--container {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $black;

            &:hover {
                color: $black;
                text-decoration: none;
            }
        }

        &--content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 22px;
            padding-right: 22px;

            @include media-breakpoint-up(md) {
                padding-left: 34px;
                padding-right: 34px;
            }
        }

        &--text {
            text-align: center;
            line-height: 1.2;
            font-size: 20px;
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }
        }

        &--larrow {
            width: 50px;
            height: auto;
            transform: rotate(180deg);
        }

        &--rarrow {
            width: 50px;
            height: auto;
        }
    }

    &__quotes {
        background: $color4;
        padding-top: 64px;
        padding-bottom: 64px;

        .carousel {
            width: 100%;
            height: 100%;
            background: $white;

            &-container {
                margin: auto;
                padding-right: 28px;
                padding-left: 28px;

                @include media-breakpoint-up(md) {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            &-wrapper {
                position: relative;
                height: 480px;

                &:after,&:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    pointer-events: none;
                }

                &:after {
                    border-top: 2px solid $color2;
                    border-bottom: 2px solid $color2;
                    width: 100vw;
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    @include media-breakpoint-up(md) {
                        width: calc(100% + 60px);
                    }

                    @include media-breakpoint-up(lg) {
                        width: calc(100% + 96px);
                    }
                }

                &:before {
                    border-left: 2px solid $color2;
                    border-right: 2px solid $color2;
                    width: 100%;
                    height: calc(100% + 60px);
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                @include media-breakpoint-up(md) {
                    height: 330px;
                }

                @include media-breakpoint-up(lg) {
                    height: 460px;
                }
            }

            &-indicators {
                margin-bottom: 0px;
                bottom: -42px;

                li {
                    width: 11px;
                    height: 11px;
                    border: 1px solid $color2;
                    opacity: 1;

                    &.active {
                        background: $color2;
                    }
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 40px;
                    bottom: 0;

                    li {
                        width: 15px;
                        height: 15px;
                        border: 2px solid $color2;
                    }
                }

                @include media-breakpoint-up(lg) {
                    li {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            &-inner {
                height: 100%;
            }

            &-item {
                height: 100%;
                justify-content: center;
                align-items: center;

                &.active,&-prev,&-next {
                    display: flex;
                }

                &--wrapper {
                    width: 550px;
                    height: auto;
                }

                &--text {
                    font-size: 20px;
                    font-weight: 300;
                    text-align: center;

                    p {
                        line-height: 1.2;

                        @include media-breakpoint-up(md) {
                            line-height: 1.3;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 17px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 24px;
                    }
                }

                &--author {
                    text-align: right;

                    p {
                        font-size: 18px;
                        margin-bottom: 0;
                        line-height: 1.2;

                        @include media-breakpoint-up(md) {
                            font-size: 12px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 18px;
                        }
                    }

                    span, a {
                        color: $color2;
                        font-size: 15px;

                        @include media-breakpoint-up(md) {
                            font-size: 10px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 15px;
                        }
                    }

                    a {
                        transition: $transition;

                        &:hover {
                            text-decoration: none;
                            color: $color1;
                        }
                    }
                }

                &--img {
                    margin-top: 14px;
                    margin-left: 46px;
                    width: 103px;
                    height: auto;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                        margin-left: 0;
                        width: 74px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 103px;
                    }
                }
            }

            &-header {
                text-align: center;
                margin-bottom: 50px;
                @include fluid-type(360px, 1920px, 20px, 30px);
            }
        }
    }

    &__prefooter {
        &--item {
            width: 100%;
            height: 140px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                height: auto;
            }
            // &:before {
            //     content: "";
            //     position: absolute;
            //     top: calc(50% - 8px);
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     width: 150px;
            //     height: 6px;
            //     background: $color3;

            //     @include media-breakpoint-up(md) {
            //         width: 106px;
            //     }

            //     @include media-breakpoint-up(lg) {
            //         width: 150px;
            //     }
            // }

            // &:after {
            //     content: "";
            //     position: absolute;
            //     top: calc(50% + 8px);
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     width: 90px;
            //     height: 6px;
            //     background: $color3;

            //     @include media-breakpoint-up(md) {
            //         width: 65px;
            //     }

            //     @include media-breakpoint-up(lg) {
            //         width: 90px;
            //     }
            // }
        }
    }

    &__grid {
        width: 100%;
        display: grid;
        grid-template-columns: 68% 25% 7%;
        grid-template-rows: 168px 118px 184px 50px;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 413px 355px 1fr;
            grid-template-rows: 204px auto auto;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 700px 500px 1fr;
            grid-template-rows: 224px auto auto;
        }

        &--logo {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: -1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 270px;

            a {
                display: block;
            }

            @include media-breakpoint-up(md) {
                width: 310px;
            }

            @include media-breakpoint-up(lg) {
                top: 40px;
                width: 324px;
            }

            svg {
                width: 100%;
                height: auto;
            }
        }

        &--bg1 {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
            background: $color2;

            @include media-breakpoint-up(md) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }

        &--bg2 {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: -1;
            background: $color1;
            transition: $transition;

            @include media-breakpoint-up(md) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 3;
                grid-column-end: -1;
            }
        }

        &--bg3 {
            grid-row-start: 2;
            grid-row-end: -1;
            grid-column-start: 1;
            grid-column-end: 3;
            background: $color3;
            transition: $transition;
        }

        &--text3 {
            grid-row-start: 2;
            grid-row-end: -1;
            grid-column-start: 1;
            grid-column-end: 3;
            line-height: 1.2;
            position: relative;
            transition: $transition;
            color: $color1;

            &:after {
                content: 'flickity';
                display: none;
            }

            @include media-breakpoint-up(md) {
                grid-row-start: 2;
                grid-row-end: -1;
                grid-column-start: 2;
                grid-column-end: 3;
                padding-top: 11px;
                padding-right: 66px;
                padding-left: 46px;
                font-size: 24px;
                color: $black;

                &:after {
                    content: '';
                }
            }

            @include media-breakpoint-up(lg) {
                padding-top: 54px;
                padding-right: 155px;
                padding-left: 55px;
                font-size: 29px;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 8px;
            }

            p {
                margin-bottom: 0;
                font-weight: 300;
            }

            .flickity-page-dots {
                bottom: 60px;

                li {
                    border-radius: unset;
                    border: 1px solid $color2;
                    width: 13px;
                    height: 13px;
                    background: transparent;
                    opacity: 1;

                    &.is-selected {
                        background: $color2;
                    }
                }
            }

            .home-slider__item {
                width: 100%;
                height: 100%;
                padding-top: 30px;
                padding-left: 35px;
                padding-right: 56px;

                p {
                    font-weight: 400;
                }

                &--first {
                    font-size: 24px;
                }

                &--second {
                    font-size: 26px;
                    padding-left: 18px;
                    padding-right: 18px;

                    @media only screen and (max-width: 359px) {
                        font-size: 24px;
                    }
                }

                &--third {
                    font-size: 21px;
                    font-weight: 400;
                    padding-top: 18px;
                    padding-left: 25px;
                    padding-right: 60px;

                    @media only screen and (max-width: 359px) {
                        font-size: 19px;
                    }
                }
            }
        }

        &--buttons {
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(100% - 47px);
            left: 4%;
            width: 100%;

            @include media-breakpoint-up(md) {
                top: calc(100% - 30px);
                left: 0;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: flex-start;
                padding: 0 55px;
                top: calc(100% - 10px);
            }

            @include media-breakpoint-up(xl) {
                padding: 0;
                padding-right: 20px;
            }

            .register-acc {
                position: relative;
                margin-bottom: 20px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    margin-right: 30px;
                }

                img {
                    position: absolute;
                    top: -45px;
                    left: 13px;
                    pointer-events: none;
                }
            }
        }

        &--bg4 {
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 3;
            grid-column-end: -1;
            background: $white;

            @include media-breakpoint-up(md) {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 3;
                grid-column-end: -1;
                background: $color1;
            }
        }

        &--text4 {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 4;
            color: $white;
            padding: 60px 0;
            font-weight: 300;
            font-size: 24px;
            line-height: 1.2;

            @include media-breakpoint-up(md) {
                padding: 18px 20px 25px 26px;
                font-size: 18px;
            }

            @include media-breakpoint-up(lg) {
                padding: 6px 65px 40px 36px;
                font-size: 24px;
            }

            @include media-breakpoint-up(xl) {
                padding: 6px 20px 40px 36px;
            }

            p {
                margin-bottom: 0;
            }
        }

        &--bg5 {
            grid-row-start: 4;
            grid-row-end: -1;
            grid-column-start: 3;
            grid-column-end: -1;
            background: $color4;

            @include media-breakpoint-up(md) {
                grid-row-start: 3;
                grid-row-end: -1;
                grid-column-start: 3;
                grid-column-end: -1;
                background: $white;
            }
        }

        &--text5 {
            grid-row-start: 3;
            grid-row-end: -1;
            grid-column-start: 3;
            grid-column-end: 4;
            color: $color1;
            font-size: 16px;
            line-height: 1.2;

            @include media-breakpoint-up(md) {
                padding: 20px 20px 25px 26px;
            }

            @include media-breakpoint-up(lg) {
                padding: 30px 65px 36px 36px;
            }

            @include media-breakpoint-up(xl) {
                padding: 30px 20px 36px 36px;
            }

            p {
                margin-bottom: 0;
                font-weight: 300;
            }
        }

        &.second-slide {
            .home__grid--bg2 {
                background: $color3;
            }

            .home__grid--bg3 {
                background: $color1;
            }

            .home__grid--text3 {
                color: $white;
            }
        }

        &.third-slide {
            .home__grid--bg2 {
                background: $color1;
            }

            .home__grid--bg3 {
                background: $color10;
            }

            .home__grid--text3 {
                color: $color1;
            }
        }
    }

    &__main {
        color: $white;
        background-color: $color14;
        background-image: url('/img/homepage/main-bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 70px 0px;
        margin-top: 65px;

        @include media-breakpoint-up(lg) {
            margin-top: 78px;
        }

        &--title {
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 1.2;
            @include fluid-type(360px, 1920px, 32px, 38px);

            strong {
                font-weight: 700;
            }
        }

        &--subtitle {
            margin-bottom: 20px;
            font-weight: 300;
            @include fluid-type(360px, 1920px, 22px, 24px);
        }

        &--list {
            padding: 15px 0 15px 20px;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 18px;
        }

        &--img {
            margin-top: 40px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    &__underMain {
        &--text {
            text-align: center;
            margin-bottom: 0;
            padding: 60px 0;
            color: $color1;
            @include fluid-type(360px, 1920px, 18px, 30px);
        }
        .black-friday-banner {
            text-align: center;
            padding-top: 60px;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

// 7. Pages
// -------------------
.page {
    &__header {
        width: 100%;
        height: 120px;
        margin-top: 65px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            height: 258px;
        }

        @include media-breakpoint-up(lg) {
            height: 352px;
        }

        &--image {
            width: 100%;
            height: 100%;
            background-image: url('../img/samples/header-bg.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        &--title, &--subtitle {
            color: $white;
            line-height: 1.3;
        }
    }

    &__prefooter {
        background-image: url('/img/common/banner-grid-bg.svg');

        &--wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(md) {
                height: 340px;
            }
        }

        &--content {
            position: relative;
            padding: 91px 20px;

            @include media-breakpoint-up(md) {
                padding: 135px 0;
            }

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }

        &--text {
            color: $color11;
            font-size: 20px;
            text-align: center;
            position: relative;
            line-height: 1.2;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }

            p {
                margin-bottom: 30px;
            }
        }
    }

    .home__quotes {
        padding-bottom: 90px;

        @include media-breakpoint-up(md) {
            padding-bottom: 102px;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 64px;
        }
    }

    .home__tabs {
        .nav-link {
            flex-direction: row;
            justify-content: flex-start;

            img,svg {
                margin-right: 15px;

                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }
            }

            span {
                text-transform: uppercase;
            }
        }
    }
}

// 8. Features
// -------------------
.features {
    &__tabs {
        &--heading {
            padding-top: 14px;
            padding-bottom: 14px;
            background: $color6;
            border-bottom: 1px solid $color1;
            border-top: 1px solid $color1;
            transition: $transition;

            @include media-breakpoint-up(lg) {
                padding-top: 0;
                padding-bottom: 0;
            }

            .nav {
                border: none;
                justify-content: space-between;

                &-link {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    padding-right: 8px;
                    padding-left: 8px;
                    border: none;
                    color: $color1;
                    transition: $transition;

                    span {
                        text-transform: uppercase;
                        font-size: 16px;

                        @include media-breakpoint-up(md) {
                            font-size: 11px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-up(xl) {
                            font-size: 15px;
                        }
                    }

                    &.active {
                        color: $white;
                        background-color: $color1;
                        border-color: $color1;
                        border-radius: unset;
                    }
                }
            }

            .custom-select {
                width: 200px;
                margin: auto;
                display: block;
                text-transform: uppercase;

                option {
                    text-transform: none;
                }
            }

            &.stickyTabs {
                z-index: 3;
                background: $color6;
                position: fixed;
                top: 65px;
                width: 100%;
                border-top: 0px;

                @include media-breakpoint-up(lg) {
                    top: 77px;
                }
            }
        }

        &--content {
            padding-top: 30px;

            @include media-breakpoint-up(lg) {
                padding-top: 50px;
            }

            .tab {
                &-content {
                    width: 100%;
                }
            }

            &-title {
                text-align: center;
                font-size: 17px;
                color: $color1;
                position: relative;
                margin-bottom: 60px;
                line-height: 1.2;

                &:after,&:before {
                    content: "";
                    position: absolute;
                    bottom: -13px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $color3;
                    height: 4px;
                    width: 90px;
                }

                &:before {
                    bottom: -24px;
                    width: 50px;

                    @include media-breakpoint-up(md) {
                        bottom: -26px;
                    }
                }

                @include media-breakpoint-up(md) {
                    font-size: 25px;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 105px;
                }
            }
        }

        &--row {
            &-text {
                color: $color1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-top: 20px;
                padding-bottom: 20px;
                font-weight: 300;
                font-size: 20px;

                >img,svg {
                    margin-bottom: 16px;
                    width: 46px;
                    height: auto;
                }

                &Title {
                    font-size: 22px;
                    font-weight: bolder;

                    @include media-breakpoint-up(md) {
                        margin-bottom: 20px;
                    }
                }

                .register-acc {
                    margin-top: 20px;

                    @include media-breakpoint-up(md) {
                        margin-top: 30px;

                        span {
                            font-size: 14px;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        span {
                            font-size: 18px;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            &.alternative {
                background: $color4;
                margin-top: 45px;

                .features__tabs--row-text {
                    color: $black;
                }

                img {
                    display: block;
                    margin: auto;
                    // border: 2px solid $color2;
                    // box-shadow: 6px 6px 0px 0px $color2;
                    border: none;
                    box-shadow: none;
                    margin-bottom: 30px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 120px;

                    img {
                        // margin-top: -40px;
                        // margin-bottom: 50px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &--single {
            &-text {
                text-align: center;
                color: $color1;
                @include fluid-type(360px, 760px, 18px, 22px);
                padding: 25px 0 45px;

                @include media-breakpoint-up(md) {
                    padding: 30px 0 45px;
                }

                @include media-breakpoint-up(lg) {
                    padding: 100px 0 170px;
                }

                p {
                    position: relative;
                    font-weight: 300;
                    margin-bottom: 40px;
                    @include fluid-type(360px, 760px, 20px, 26px);

                    &:after, &:before {
                        content: "";
                        position: absolute;
                        bottom: -7px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: $color3;
                        height: 4px;
                        width: 90px;
                    }

                    &:before {
                        bottom: -18px;
                        width: 50px;

                        @include media-breakpoint-up(md) {
                            bottom: -20px;
                        }
                    }
                }
            }
        }

        &--figure {
            position: absolute;
            top: -30px;
            left: 0;

            @include media-breakpoint-up(md) {
                top: 42px;
                left: 86px;
            }

            @include media-breakpoint-up(lg) {
                top: 42px;
                left: 7px;
            }

            @include media-breakpoint-up(xl) {
                left: 78px;
            }
        }
    }

    &__video {
        position: relative;

        &--container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 270px;
            background: $color3;

            @include media-breakpoint-up(md) {
                height: 560px;
            }

            .plyr {
                width: 300px;
                height: 170px;
                margin-bottom: 26px;
                position: relative;
                overflow: visible;

                &:after,&:before {
                    content: "";
                    position: absolute;
                    z-index: -1; //999
                }

                &:after {
                    width: calc(100% + 40px);
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    border-top: 2px solid $color1;
                    border-bottom: 2px solid $color1;

                    @include media-breakpoint-up(md) {
                        width: calc(100% + 96px);
                    }
                }

                &:before {
                    width: 100%;
                    height: calc(100% + 30px);
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-left: 2px solid $color1;
                    border-right: 2px solid $color1;

                    @include media-breakpoint-up(md) {
                        height: calc(100% + 50px);
                    }
                }

                iframe {

                    width: 100%;
                    height: 100%;
                    border: none;

                }

                .plyr {
                    &__control {
                        background: transparent;
                        box-shadow: none;
                        outline: none;

                        img {
                            width: 60px;
                            height: auto;

                            @include media-breakpoint-up(md) {
                                width: 125px;
                            }
                        }
                    }
                }


                @include media-breakpoint-up(md) {
                    width: 600px;
                    height: 338px;
                    margin-bottom: 50px;
                }
            }

            >.plyr__video-embed {
                width: 300px;
                height: 170px;
                margin-bottom: 26px;
                padding-bottom: 0;
                position: relative;
                overflow: visible;

                &:after,&:before {
                    content: "";
                    position: absolute;
                    z-index: 1; //999
                    pointer-events: none;
                }

                &:after {
                    width: calc(100% + 40px);
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    border-top: 2px solid $color1;
                    border-bottom: 2px solid $color1;

                    @include media-breakpoint-up(md) {
                        width: calc(100% + 96px);
                    }
                }

                &:before {
                    width: 100%;
                    height: calc(100% + 30px);
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-left: 2px solid $color1;
                    border-right: 2px solid $color1;

                    @include media-breakpoint-up(md) {
                        height: calc(100% + 50px);
                    }
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    border: none;
                }

                @include media-breakpoint-up(md) {
                    width: 600px;
                    height: 338px;
                    margin-bottom: 50px;
                }
            }

            a {
                color: $black;
                font-size: 18px;
                font-weight: 300;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }

                &:hover {
                    font-weight: 400;
                    text-decoration: none;
                }

                span {
                    text-decoration: underline;
                }
            }
        }
    }
}

// 9. Subscriptions
// -------------------
.subscriptions {
    background: $color4;
    padding-top: 64px;
    padding-bottom: 64px;
    z-index: -1;

    .black-friday-banner {
        text-align: center;
        padding-bottom: 90px;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__wrapper {
        position: relative;
        height: auto;
        margin: auto;

        @include media-breakpoint-up(md) {

            &:after,&:before {
                content: "";
                position: absolute;
                z-index: 2;
                pointer-events: none;
            }

            &:after {
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                width: calc(100% + 70px);
                height: 100%;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:before {
                border-left: 2px solid $color2;
                border-right: 2px solid $color2;
                width: 100%;
                height: calc(100% + 42px);
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        @include media-breakpoint-up(lg) {

            &:after {
                width: calc(100% + 96px);
            }

            &:before {
                height: calc(100% + 60px);
            }
        }
    }

    &__container {
        width: 100%;
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(md) {
            background: $white;
        }

        #subs-slider {
            flex-direction: column;

            .flickity {
                &-viewport {
                    overflow: visible;
                }

                &-page-dots {
                    bottom: -50px;

                    li {
                        width: 14px;
                        height: 14px;
                        background: transparent;
                        border-radius: unset;
                        border: 1px solid $color2;
                        opacity: 1;

                        &.is-selected {
                            background: $color2;
                        }
                    }
                }
            }

            &:after {
                content: 'flickity';
                display: none;
            }

            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: row;

                &:after {
                    content: '';
                }
            }

            &.annualCharge {
                .annual {
                    display: block;
                }

                .monthly {
                    display: none;
                }
            }
        }
    }

    &__item {
        position: relative;
        min-height: 100%;
        height: auto;
        text-align: center;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 178px;
        background: $white;

        @include media-breakpoint-up(md) {
            width: unset;
            background: none;
        }

        @include media-breakpoint-up(lg) {
            padding: 50px 0;
        }

        &:first-of-type {
            &:before {
                content: "";
                position: absolute;
                border-left: 2px solid $color2;
                height: calc(100% + 38px);
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:after {
                content: "";
                position: absolute;
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                width: calc(100% + 25px);
                height: 100%;
                top: 0;
                right: 0;
            }

            @include media-breakpoint-up(md) {
                &:before,&:after {
                    content: none;
                }
            }
        }

        &:last-of-type {
            &:before {
                content: "";
                position: absolute;
                border-right: 2px solid $color2;
                height: calc(100% + 38px);
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:after {
                content: "";
                position: absolute;
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                width: calc(100% + 25px);
                height: 100%;
                top: 0;
                left: 0;
            }

            @include media-breakpoint-up(md) {
                &:before,&:after {
                    content: none;
                }
            }
        }

        &.alternate {
            background: $color3;
            border-top: 2px solid $color2;
            border-bottom: 2px solid $color2;

            @include media-breakpoint-up(md) {
                background: none;
                border-top: none;
                border-bottom: none;
            }

            &:before,&:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: calc(100% + 38px);
                left: 0;

                @include media-breakpoint-up(md) {
                    height: calc(100% + 42px);
                }

                @include media-breakpoint-up(lg) {
                    height: calc(100% + 60px);
                }
            }

            &:before {
                background: $color3;
                z-index: -1;
            }

            &:after {
                border-left: 2px solid $color1;
                border-right: 2px solid $color1;
                pointer-events: none;
            }

            .subscriptions__item {
                &--price {
                    color: $white;
                }

                &--info {
                    li {
                        &:after {
                            background: $white;
                        }
                    }
                }
            }
        }

        span {
            display: block;
        }

        &--title {
            font-size: 22px;

            @include media-breakpoint-up(md) {
                font-size: 28px;
                padding-bottom: 15px;
            }
        }

        &--price {
            color: $color3;
            line-height: 1;
            font-size: 38px;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                font-size: 46px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 66px;
            }

            &.annual {
                display: none;
            }

            &.askUs {
                font-size: 22px;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 30px;
                }
            }
        }

        &--info {
            list-style: none;
            margin-bottom: 30px;
            padding: 0 22px;
            display: block;

            &.annual {
                display: none;
            }

            @include media-breakpoint-up(md) {
                padding: 0 28px;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 38px;
            }

            li {
                position: relative;
                margin-bottom: 5px;
                font-size: 10px;

                @include media-breakpoint-up(md) {
                    font-size: 13px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 70px;
                    height: 1px;
                    background: $color3;
                }
            }
        }

        &--button {
            width: 100%;
            padding: 0 22px;
            display: block;

            &.annual {
                display: none;
            }

            span {
                font-size: 14px;
                padding-bottom: 15px;
            }

            a {
                width: 100%;
                height: auto;
                min-height: 22px;
                font-size: 11px;
                padding: 6px 4px;
            }

            @include media-breakpoint-up(md) {
                padding: 0 28px;

                span {
                    font-size: 16px;
                }

                a {
                    width: 100%;
                    min-height: 30px;
                    font-size: 14px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 0 38px;

                span {
                    font-size: 18px;
                }

                a {
                    width: 100%;
                    min-height: 40px;
                    font-size: 20px;
                }
            }

            .annual {
                display: none;
            }
        }

        &--saving {
            text-align: center;
            color: $color1;
            font-weight: 400;
            line-height: 1.2;
            @include fluid-type(360px, 1920px, 16px, 20px);
            padding: 0 10px;
            margin-bottom: 30px;
            transition: $transition;
            display: block;

            &.annual {
                display: none;
            }
        }
    }

    &__header {
        .page__header {
            &--image {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: linear-gradient(90deg, $color3 0%, $color2 50%, $color1 100%);
                text-align: center;

                @include media-breakpoint-up(md) {
                    padding-top: 65px;
                }

                @include media-breakpoint-up(lg) {
                    padding-top: 80px;
                }

                .custom-switch {
                    color: $white;
                    font-size: 16px;
                    padding-left: 0;
                    margin-top: 5px;

                    @include media-breakpoint-up(md) {
                        font-size: 24px;
                        margin-top: 10px;
                    }

                    label {
                        margin-left: 65px;
                        cursor: pointer;
                        transform: translateY(-5px);

                        @include media-breakpoint-up(md) {
                            margin-left: 75px;
                        }

                        @include media-breakpoint-up(lg) {
                            transform: unset;
                        }

                        &:before {
                            cursor: pointer;
                            outline: none!important;
                            box-shadow: none!important;
                            border-radius: 25px;
                            border: 2px solid $white!important;
                            background: transparent!important;
                            left: -56px;
                            width: 46px;
                            height: 26px;

                            @include media-breakpoint-up(md) {
                                height: 30px;
                                width: 60px;
                                left: -68px;
                            }
                        }

                        &:after {
                            border-radius: 50%;
                            background: $white;
                            height: 20px;
                            width: 20px;
                            top: 7px;
                            left: -34px;

                            @include media-breakpoint-up(md) {
                                height: 24px;
                                width: 24px;
                                left: -36px;
                            }
                        }
                    }

                    span {
                        cursor: pointer;

                        @media only screen and (max-width: 359px) {
                            font-size: 14px;
                        }

                        &.active {
                            text-decoration: underline;
                        }
                    }

                    .custom-control-input:checked~.custom-control-label::after {
                        outline: none!important;
                        box-shadow: none!important;
                        transform: translateX(-18px);

                        @include media-breakpoint-up(md) {
                            transform: translateX(-28px);
                        }
                    }
                }
            }

            &--title {
                display: block;
                font-weight: 300;
                font-size: 31px;

                @include media-breakpoint-up(md) {
                    font-size: 37px;
                }
            }

            &--subtitle {
                display: block;
                font-size: 18px;

                @media only screen and (max-width: 359px) {
                    font-size: 16px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }
            }
        }
    }

    &__explanation {
        margin: auto;
        margin-top: 70px;

        @include media-breakpoint-up(lg) {
            margin-top: 55px;
        }

        em {
            font-style: normal;
            font-size: 16px;
        }
    }
}

// 10. FAQ
// -------------------
.faq {
    &__wrapper {
        background: $color4;

        @include media-breakpoint-up(md) {
            background: linear-gradient(0deg, $color4 calc(100% - 150px), $white 150px);
            margin-top: 40px;
        }

        @include media-breakpoint-up(lg) {
            background: linear-gradient(90deg, $white 50%, $color4 50%);
            margin-top: 0;
        }
    }

    &__img {
        position: relative;
        background: $white;

        @include media-breakpoint-up(md) {
            background: none;
        }

        @include media-breakpoint-up(lg) {
            z-index: 2;
        }

        svg {
            width: 205px;
            height: auto;
            position: relative;
            top: 65px;
            left: 10px;

            @include media-breakpoint-up(md) {
                top: 15px;
                left: calc(50% - 80px);
                transform: translateX(-50%);
            }

            @include media-breakpoint-up(lg) {
                position: static;
                margin-top: 90px;
                width: 310px;
                transform: none;
            }

            .a{fill:#e0e1e3;}.b{fill:#201851;}.c{fill:#3eb7ba;}.d{fill:#e0e1e1;}.e{fill:#ffc000;}.f,.g{fill:none;stroke-width:2px;}.f{stroke:#306;}.g{stroke:#3eb7ba;}
        }
    }

    &__text {
        padding: 105px 22px 60px 57px;
        position: relative;
        background-image: url('/img/common/cloudt-bg.svg');
        background-repeat: no-repeat;
        background-position: center 150px;

        @include media-breakpoint-up(md) {
            padding: 60px 30px 60px 100px;
            background-position: center 70px;
        }

        @include media-breakpoint-up(lg) {
            padding: 60px 150px 60px 220px;
            background-color: $color4;
            background-position: center 130px;
            z-index: 1;
        }

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: -40px;
            right: 50px;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-left: 50px solid $color4;
            transform: rotate(0deg);

            @include media-breakpoint-up(md) {
                top: -110px;
                left: calc(50% + 170px);
                right: unset;
            }

            @include media-breakpoint-up(lg) {
                transform: rotate(-45deg);
                top: 190px;
                left: -15px;
            }
        }

        ul {
            padding: 0;
            margin-bottom: 0;
            list-style: none;

            li {
                margin-bottom: 15px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: -5px;
                    left: -35px;
                    width: 25px;
                    height: 25px;
                    background-image: url('/img/common/check1.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }

                &:nth-of-type(3n - 1) {
                    &:before {
                        background-image: url('/img/common/check2.svg');
                    }
                }

                &:nth-of-type(3n) {
                    &:before {
                        background-image: url('/img/common/check3.svg');
                    }
                }

                p {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 500px;
                }
            }
        }

        &--question {
            appearance: none;
            display: block;
            box-shadow: unset;
            outline: unset;
            border: unset;
            background: unset;
            text-align: left;
            width: 100%;
            font-size: 18px;
            padding: 5px 35px 5px 0;
            position: relative;

            &:after {
                content: "—";
                position: absolute;
                top: 0;
                right: 0;
                font-weight: 300;
                font-size: 28px;
            }

            &.collapsed {
                &:after {
                    content: "+";
                    right: 6px;
                    top: 1px;
                    font-size: 30px;
                }
            }

            strong {
                color: $color2;
            }

            u {
                color: $color1;
                font-weight: bolder;
                text-decoration: none;
            }
        }

        &--answer {
            font-weight: 300;
        }

        &--title {
            @include fluid-type(360px, 1920px, 26px, 34px);
            margin-left: -35px;
            margin-bottom: 40px;
        }
    }
}

// 11. Login
// -------------------
.login {
    background: $color6;
    margin-top: 65px;
    padding-bottom: 24px;

    @include media-breakpoint-up(md) {
        padding-bottom: 90px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 24px;
        margin-top: 108px;
    }

    &__title {
        text-align: center;
        color: $color1;
        font-size: 19px;
        margin-top: 30px;
        margin-bottom: 20px;
        padding-right: 37px;
        padding-left: 37px;
        line-height: 1.3;

        span {
            font-weight: 300;
        }

        p {margin-bottom: 0;}

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
            font-size: 25px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &__form {
        background: url('/img/common/login-background.svg');
        background-position: 70px center;
        background-repeat: no-repeat;
        padding-bottom: 235px;
        padding-left: 35px;
        padding-right: 35px;

        @include media-breakpoint-up(md) {
            background: none;
            padding-bottom: 0;
            padding-left: 15px;
            padding-right: 15px;
        }

        &--wrapper {
            position: relative;
            width: 100%;
            background: $whiteOpacity;
            padding: 30px 25px;

            &:after,&:before {
                content: "";
                position: absolute;
                pointer-events: none;
            }

            &:after {
                border-left: 2px solid $color2;
                border-right: 2px solid $color2;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                height: calc(100% + 60px);

                @include media-breakpoint-up(md) {
                    height: calc(100% + 15px);
                }
            }

            &:before {
                border-top: 2px solid $color2;
                border-bottom: 2px solid $color2;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                width: 100vw;

                @include media-breakpoint-up(md) {
                    width: calc(100% + 20px);
                }
            }

            input,button {
                border-radius: 0;
            }

            input {
                border: 1px solid $color2;
            }

            label {
                color: $black;
                font-size: 17px;

                &.custom-control-label {
                    font-size: 15px;
                    font-weight: 300;
                }

                @include media-breakpoint-up(md) {
                    font-size: 16px;

                    &.custom-control-label {
                        font-size: 14px;
                    }
                }
            }

            .custom-control-input:checked~.custom-control-label::before {
                border-color: $color2;
                background-color: $color2;
            }

            .custom-checkbox {
                .custom-control-label {
                    &:before {
                        border: 1px solid $color2;
                        border-radius: 0;
                    }

                    &:before,&:after {
                        top: 1px;
                    }
                }
            }

            .form-group {
                margin-bottom: 26px;
            }

            .form-text {
                font-size: 15px;
                margin-bottom: 30px;
                font-weight: 300;

                a {
                    color: $color2;
                    text-decoration: underline;
                }
            }

            button {
                color: $black;
                background-color: $color2;
                border-color: $color2;
                font-size: 18px;
                font-weight: 300;
                width: 100%;
                transition: all .3s ease;
                padding: 10px 6px;

                span {
                    color: $white;
                    text-decoration: underline;
                }

                &:hover {
                    color: $white;
                    background: $color1;
                    border-color: $color1;
                }
            }

            @include media-breakpoint-up(md) {
                padding: 38px 50px;
                background: $white;

                &:after {
                    height: calc(100% + 30px);
                    width: 100%;
                }

                &:before {
                    height: 100%;
                    width: calc(100% + 40px);
                }

                label {
                    font-size: 17px;

                    &.custom-control-label {
                        font-size: 15px;
                    }
                }

                button {
                    font-size: 16px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 38px 65px;

                &:after {
                    height: calc(100% + 60px);
                    width: 100%;
                }

                &:before {
                    height: 100%;
                    width: calc(100% + 100px);
                }

                button {
                    font-size: 18px;
                }
            }
        }
    }
}

// 12. Contact
// -------------------
.contact {
    margin-top: 65px;
    padding-bottom: 35px;

    @include media-breakpoint-up(md) {
        padding-bottom: 90px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 150px;
        margin-top: 108px;
    }

    &__title {
        text-align: center;
        color: $black;
        font-size: 19px;
        margin-top: 30px;
        margin-bottom: 20px;
        padding-right: 37px;
        padding-left: 37px;
        line-height: 1.3;
        font-weight: 300;
        text-transform: lowercase;

        p {margin-bottom: 0;}

        @include media-breakpoint-up(md) {
            margin-bottom: 100px;
            font-size: 25px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &__form {
        position: relative;
        background: $color6;
        border: 2px solid $color1;
        box-shadow: 6px 6px 0px $color1;
        padding: 8% 8% 5%;
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            box-shadow: 20px 20px 0px $color1;
            padding: 8% 10% 5%;
            margin-bottom: 0;
        }

        input,textarea {
            background: $white;
            border-radius: 0;
            border: none;

            &.input-error {
                border: 1px solid red;
            }
        }

        textarea {
            width: 100%;
            padding: 0.375rem 0.75rem;
        }

        label {
            color: $color1;
            font-size: 16px;
        }

        button {
            color: $white;
            background: $color2;
            box-shadow: 0px 3px 6px #0000005D;
            border: none;
            padding: 16px 0;
            width: 80%;
            transition: $transition;

            &:focus {
                outline: none;
            }

            &:hover {
                background: $color1;
            }
        }

        &--button {
            text-align: center;
        }

        &--messages {
            position: relative;
            text-align: center;
            margin-top: 20px;

            .success-message {
                color: green;
            }

            .error-message {
                color: red;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    &__text {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        p {
            margin-bottom: 0;
            color: $color1;
            font-size: 22px;
        }
    }

    &__details {
        padding-left: 0;
        margin: 30px 0;
        list-style: none;
        font-weight: 300;
    }

    &__phone, &__email {
        position: relative;
        padding-left: 60px;
        margin-bottom: 20px;

        &:before {
            content: "";
            background-image: url('/img/common/check1.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 45px;
            height: 45px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        a {
            color: $black;
            font-weight: 700;
            font-size: 20px;
        }
    }

    &__email {
        &:before {
            background-image: url('/img/common/check2.svg');
        }
    }

    &__img {
        display: block;
        margin: auto;
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin: unset;
            margin-top: 70px;
        }
    }

    .static__container {
        margin-bottom: 20px;
    }
}

// 13. Static
// -------------------
.static {
    padding-top: 65px;

    @include media-breakpoint-up(lg) {
        padding-top: 108px;
    }

    &__container {
        padding: 0 6%;

        @include media-breakpoint-up(md) {
            padding: 0 15%;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 26%;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        margin-top: 40px;
        margin-bottom: 45px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        h1 {
            font-size: 28px;
            font-weight: 300;
            margin-bottom: 10px;
            text-align: center;

            @include media-breakpoint-up(md) {
                margin-left: 30px;
                margin-bottom: 0;
                text-align: left;
            }

            span {
                font-weight: 400;
            }
        }
    }

    &__text {
        margin-bottom: 40px;

        p {
            font-weight: 300;
            font-size: 16px;
        }
    }

    &__img {
        img {
            display: block;
            margin: auto;
            border: 2px solid $color2;
            box-shadow: 6px 6px 0px 0px $color2;
        }

        &.alternate {
            img {
                border: 2px solid $color1;
                box-shadow: 6px 6px 0px 0px $color1;
            }
        }
    }
}

// 14. Infographics
// -------------------
.infographics {
    text-align: center;
    background-color: $color4;
    padding: 60px 0;

    &__title {
        @include fluid-type(360px, 1920px, 18px, 24px);
        margin-bottom: 45px;
    }

    &__item {
        opacity: 0;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        &--counter {
            line-height: 1;
            @include fluid-type(360px, 1920px, 30px, 50px);
            margin-bottom: 5px;

            @include media-breakpoint-up(md) {
                margin-bottom: 10px;
            }
        }

        &--subtitle {
            line-height: 1;
            font-weight: 300;
            @include fluid-type(360px, 1920px, 16px, 18px);
            margin-bottom: 0;
        }
    }
}

// 15. Redactor Custom Styles
.turquoise-button {
    margin: 30px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    a {
        text-decoration: none;
        display: inline-block;
        color: $white;
        background: #34a2a2;
        transition: $transition;
        line-height: 1.1;
        text-align: center;
        font-weight: 400;
        @include fluid-type(360px, 1920px, 18px, 20px);
        border-radius: 40px;
        padding: 14px 26px;

        &:hover {
            text-decoration: none;
            //box-shadow: 6px 6px 0px $color1;
            background: $color1;
        }
    }
}

.blue-button {
    margin: 30px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    a {
        text-decoration: none;
        display: inline-block;
        color: $white;
        background: $color1;
        transition: $transition;
        line-height: 1.1;
        text-align: center;
        font-weight: 400;
        @include fluid-type(360px, 1920px, 18px, 20px);
        border-radius: 40px;
        padding: 14px 26px;

        &:hover {
            text-decoration: none;
            background: $color2;
        }
    }
}

.yellow-button {
    margin: 30px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    a {
        text-decoration: none;
        display: inline-block;
        color: $color1;
        background: $color3;
        transition: $transition;
        line-height: 1.1;
        text-align: center;
        font-weight: 400;
        @include fluid-type(360px, 1920px, 18px, 20px);
        border-radius: 40px;
        padding: 14px 26px;

        &:hover {
            text-decoration: none;
            background: $color2;
        }
    }
}

.turquoise-highlight {
    color: $color1;
    background: rgba(62, 183, 186, 0.31);
    font-weight: 400;
    display: inline-block;
    padding: 0 4px;
}