//  Cloudt Invoicing Scss
//  ------------------------


// Imports
// -------------------
@import "app";

// Variables
// -------------------
$bgColor: #F0F0F0;

// Ovewrite Styling
// -------------------
.invoicingTheme {
    background: $bgColor;

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        @include media-breakpoint-up(lg) {
            max-width: 1600px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .header {
        .invoicingButton {
            &:hover {
                .st0 {
                    fill: $color2;
                }
            }

            svg {
                .st0 {
                    fill: $color1;
                }
            }
        }

        &.alternate-nav {
            background: rgba($color: $color4, $alpha: 0.93);
            box-shadow: none;

            .collapsed {
                .navbar-toggler {
                    &__mid-icon {
                        background: #C7F7D5!important;
                    }
                }
            }
        }

        nav {
            a {
                color: $black;
            }

            .mobile-menu__logo {
                @include media-breakpoint-up(md) {
                    top: 50%;
                    left: 50%;
                }
            }

            #navbar__content {
                @include media-breakpoint-up(lg) {
                    padding: 15px 35px 15px;
                    height: 78px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 15px 5vw 15px;
                }
            }

            .navbar-toggler {
                background: $white;
                padding: 7px 7px 5px;
                border-radius: 0;
                width: 41px;
                height: 39px;
                left: 10px;
            }

            .collapsed {
                .navbar-toggler {
                    &__top-icon {
                        background: #FFF482;
                    }

                    &__mid-icon {
                        background: #C7F7D5;
                    }

                    &__bot-icon {
                        background: #FED7D8;
                    }
                }
            }
        }

        // &__content {
        //     grid-template-columns: 1fr auto 1fr;
        // }

        &__center {
            a {
                @include fluid-type(992px, 1920px, 14px, 18px);
            }
        }

        // &__left {
        //     margin-right: 0;
        // }

        &__right {
            a {
                @include fluid-type(992px, 1920px, 14px, 18px);

                &:not(:last-of-type) {
                    margin-right: 25px;
    
                    @include media-breakpoint-up(xl) {
                        margin-right: 45px;
                    }
                }
            }
        }

        &__cloud {
            z-index: -1;
            position: absolute;
            top: -2px;
            left: calc(50% - 25px);
            transform: translateX(-50%);
            width: 185px;

            @include media-breakpoint-up(md) {
                top: -4px;
                left: calc(50% - 55px);
                width: 287px;
            }

            @include media-breakpoint-up(lg) {
                top: -5px;
                left: calc(50% - 90px);
                width: 366px;
            }
        }

        a.cloudtInvoicing, a.cloudtAccounting {
            &:hover {
                text-decoration: none;
                background: $black;
                color: $white;
            }
        }

        a.cloudtInvoicing {
            background: $color1;
            color: $white;
        }

        a.cloudtAccounting {
            background: $white;
            color: $color1;
        }
    }

    .home {
        &__grid {
            grid-template-rows: 150px;

            @include media-breakpoint-up(md) {
                grid-template-rows: 200px;
            }

            @include media-breakpoint-up(lg) {
                grid-template-rows: 300px;
            }

            &--logo {
                width: 138px;
                top: 45px;
                left: calc(50% + 8px);

                @include media-breakpoint-up(md) {
                    width: 200px;
                    top: 66px;
                    left: calc(50% + 2px);
                }

                @include media-breakpoint-up(lg) {
                    width: 260px;
                    top: 108px;
                    left: calc(50% - 20px);
                }
            }
        }

        &__welcome {
            background-image: url('/img/invoicing/home/welcome-bg.svg');
            background-repeat: no-repeat;
            background-position: center 100px;
            background-size: 95%;
            margin-top: 140px;

            @include media-breakpoint-up(md) {
                background-size: 100%;
            }

            @include media-breakpoint-up(lg) {
                background-position: center top;
                background-size: contain;
                padding-top: 40px;
                padding-bottom: 80px;
            }

            &--img {
                margin-bottom: 30px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            &--logos {
                text-align: center;
                margin-bottom: 40px;

                &-img {
                    display: block;
                    margin: 0 auto 30px;
                    
                    @include media-breakpoint-up(lg) {
                        margin: 40px auto;
                    }
                }
            }

            &--arrow {
                &-img {
                    overflow: visible;
                    width: 21px;
                    height: auto;

                    .st0{fill:$color2;}
                    .st1{fill:none;stroke:$color2;}
                }
            }

            &--content {
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }

            &--text {
                color: $color1;
                margin-bottom: 30px;
                text-align: left;

                @include media-breakpoint-up(md) {
                    margin-bottom: 40px;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 50px;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 300;
                    line-height: 1.2;
                    font-size: 16px;

                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 30px;
                    }

                    strong {
                        font-weight: 600;
                    }
                }
            }

            &--buttons {
                display: inline-flex;
                flex-direction: column;

                &-row {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    // @include media-breakpoint-up(md) {
                    //     justify-content: center;
                    // }

                    @include media-breakpoint-up(lg) {
                        justify-content: flex-start;
                    }
                }
            }

            &--button {
                display: inline-block;
                color: $white;
                background: #34a2a2;
                transition: $transition;
                line-height: 1.1;
                text-align: center;
                @include fluid-type(360px, 1920px, 18px, 20px);
                margin-bottom: 20px;
                border-radius: 40px;
                padding: 14px 26px;
                // padding: 22px 10px;
                // width: clamp(150px, 48%, 220px);

                @include media-breakpoint-up(md) {
                    margin-left: 15px;
                    margin-right: 15px;
                    // width: clamp(150px, 50%, 220px);
                }

                @include media-breakpoint-only(lg) {
                    margin-left: 0;
                    margin-right: 0;
                    // width: clamp(160px, 48%, 220px);
                }

                @include media-breakpoint-up(xl) {
                    margin-left: 0;
                    margin-right: 0;
                    // width: clamp(180px, 50%, 220px);
                }
                &:not(:last-of-type) {
                    margin-right: 20px;
                }

                &:hover {
                    text-decoration: none;
                    //box-shadow: 6px 6px 0px $color1;
                    background: $color1;
                }

                &.borderedButton {
                    @include fluid-type(360px, 1920px, 14px, 18px);
                    background: transparent;
                    color: #34a2a2;
                    border: 2px solid #34a2a2;
                    padding: 16px 10px;

                    @include media-breakpoint-only(lg) {
                        font-size: 15px;
                    }

                    &:hover {
                        background: $white;
                        //box-shadow: 6px 6px 6px rgba($color: $black, $alpha: 0.16);
                    }
                }
            }

            .black-friday-banner {
                text-align: center;
                padding-bottom: 60px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &__quotes {
            padding-bottom: 120px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 160px;
            }

            .carousel {
                &-wrapper {
                    &:after, &:before {
                        border-color: $color12;
                    }
                }

                &-indicators {
                    li {
                        border-color: $color12;

                        &.active {
                            background: $color12;
                        }
                    }
                }

                &-container {
                    position: relative;
    
                    &:before {
                        content: "";
                        z-index: -1;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: calc(100% + 210px);
                        height: calc(100% + 147px);
                        background-image: url('/img/invoicing/common/quotes-bg.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;

                        @include media-breakpoint-up(xl) {
                            height: calc(100% + 220px);
                        }
                    }
                }

                &-item {
                    &--text, &--author {
                        p {
                            color: $color12;
                        }
                    }

                }

                &-header {
                    display: none;
                }
            }
        }

        &__faq {
            background: $white;
            padding-top: 50px;
            padding-bottom: 50px;
            
            @include media-breakpoint-up(md) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
            
            @include media-breakpoint-up(lg) {
                padding-top: 80px;
                padding-bottom: 80px;
            }

            &--espa {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 50px;
                padding-left: 20px;
                padding-right: 20px;
                @include media-breakpoint-up(md) {
                    padding-top: 60px;
                }
                
                @include media-breakpoint-up(lg) {
                    padding-top: 80px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &__textButton {
            width: 100%;
            text-align: center;
            padding: 60px 0;

            @include media-breakpoint-up(md) {
                padding: 80px 0;
            }

            @include media-breakpoint-up(lg) {
                padding: 120px 0;
            }
            
            &--text {
                margin-bottom: 30px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 50px;
                }

                p {
                    color: $color1;
                    margin-bottom: 0;
                    font-weight: 400;
                    line-height: 1.2;
                    font-size: 16px;

                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 30px;
                    }
                }
            }

            .home__welcome--button {
                margin-bottom: 0;
            }
        }
    }

    .textColImgCol {
        padding-top: 50px;
        padding-bottom: 40px;
        
        @include media-breakpoint-up(md) {
            padding-top: 80px;
            padding-bottom: 70px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            padding-bottom: 80px;
        }

        &.whiteBg {
            position: relative;
            background: $white;

            @include media-breakpoint-up(lg) {
                padding-top: 130px;
            }

            &:before {
                background-image: url('/img/invoicing/common/divider-colors.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                content: "";
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                top: -28px;
                width: 91px;
                height: 57px; 
                
                @include media-breakpoint-up(lg) {
                    top: -58px;
                    width: 190px;
                    height: 117px;
                }
            }
        }

        &.rightImg {
            .textColImgCol__img {
                margin-bottom: 10px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    order: 2;
                }
            }

            .textColImgCol__content {
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }
        }

        &__content {
            color: $color1;
            text-align: center;

            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }

        &__title {
            text-align: left;
            font-weight: 400;
            margin-bottom: 25px;
            line-height: 1.3;
            font-size: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 30px;
            }

            strong {
                font-weight: 600;
            }
        }

        &__text {
            text-align: left;
            font-weight: 300;
            line-height: 1.3;
            font-size: 14px;
            margin-bottom: 12px;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
                margin-bottom: 27px;
            }

            ul {
                // list-style-image: url('/img/invoicing/common/rectangle.svg');
                padding-left: 19px;

                // &::marker {
                //     transform: translateX(-40px);
                // }

                li {
                    padding-left: 0;
                }
            }

            // Redactor Custom Styles
            .turquoise-button {
                margin: 30px 0;
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    color: $white;
                    background: #34a2a2;
                    transition: $transition;
                    line-height: 1.1;
                    text-align: center;
                    font-weight: 400;
                    @include fluid-type(360px, 1920px, 18px, 20px);
                    border-radius: 40px;
                    padding: 14px 26px;

                    &:hover {
                        text-decoration: none;
                        //box-shadow: 6px 6px 0px $color1;
                        background: $color1;
                    }
                }
            }

            .blue-button {
                margin: 30px 0;
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    color: $white;
                    background: $color1;
                    transition: $transition;
                    line-height: 1.1;
                    text-align: center;
                    font-weight: 400;
                    @include fluid-type(360px, 1920px, 18px, 20px);
                    border-radius: 40px;
                    padding: 14px 26px;

                    &:hover {
                        text-decoration: none;
                        background: $color2;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: inline-block;
            background: $color1;
            color: $white;
            // box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.36);
            transition: $transition;
            padding: 14px 26px;
            line-height: 1.1;
            font-weight: 400;
            border-radius: 40px;
            @include fluid-type(360px, 1920px, 18px, 20px);

            &:hover {
                text-decoration: none;

                @include media-breakpoint-up(lg) {
                    background: $color2;
                    // &:after {
                    //     opacity: 1;
                    // }

                    // span {
                    //     left: -40px;
                    // }
                }
            }

            // &:after {
            //     content: url('/img/invoicing/common/dots.svg');
            //     position: absolute;
            //     transform: translateY(-50%);
            //     top: 50%;
            //     right: 28px;
            //     transition: $transition;
            //     opacity: 0;
            // }

            span {
                position: relative;
                transition: $transition;
                // left: 0;
            }
        }
    }

    .boxedComment {
        position: relative;
        background: $white;
        border-radius: 39px;
        margin: 0 auto;
        max-width: 85%;
        padding: 60px 5% 60px;
        margin-top: 50px;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding: 80px 6% 100px;
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include media-breakpoint-up(lg) {
            padding: 100px 6%;
            margin-top: 80px;
            margin-bottom: 60px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 100%;
        }

        &__text {
            text-align: center;
            color: $color1;

            p {
                margin-bottom: 0;
                font-weight: 300;
                line-height: 1.3;
                font-size: 20px;

                @include media-breakpoint-up(lg) {
                    font-size: 28px;
                }

                strong {
                    font-weight: 400;
                }
            }

            .home__welcome--button {
                margin-top: 40px;
                margin-bottom: 0;
            }
        }

        &__topLeftWrap, &__topRightWrap, &__bottomLeftWrap, &__bottomRightWrap {
            position: absolute;
            height: auto;
            width: 3.5%;
        }

        &__topLeftWrap {
            top: 0;
            left: 13.8%;
            transform: translateY(-50%);
        }

        &__topRightWrap {
            top: 0;
            right: 13.8%;
            transform: translateY(-50%);
        }

        &__bottomLeftWrap {
            bottom: 0;
            left: 13.8%;
            transform: translateY(50%);
            
            @include media-breakpoint-up(md) {
                transform: translateY(calc(100% - 45px));
            }
        }

        &__bottomRightWrap {
            bottom: 0;
            right: 13.8%;
            transform: translateY(50%);
            
            @include media-breakpoint-up(md) {
                transform: translateY(calc(100% - 40px));
            }
        }
    }

    .imageBanner {
        margin-top: 40px;
        margin-bottom: 70px;
    }

    .invoicingFeatures {
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            margin-top: 95px;
        }
    }

    .contact {
        @include media-breakpoint-up(md) {
            margin-top: 100px;
        }

        @include media-breakpoint-up(lg) {
            // margin-top: 195px;
            margin-top: 150px;
        }

        &__img {
            width: 230px;

            @include media-breakpoint-up(md) {
                width: 80%;
            }

            @include media-breakpoint-up(xl) {
                width: 69%;
            }
        }

        .static__title {
            h1 {
                color: $color1;
                font-size: 34px;

                @include media-breakpoint-up(lg) {
                    font-size: 42px;
                }
            }
        }
    }

    .invoicingSubscription {
        .page__header {
            @include media-breakpoint-up(lg) {
                height: 380px;
            }

            &--title {
                color: $color1;
                font-size: 28px;

                @include media-breakpoint-up(md) {
                    font-size: 34px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 42px;
                    margin-bottom: 40px;
                }
            }

            &--image {
                background: $white;

                @include media-breakpoint-up(md) {
                    background: linear-gradient($color4 90px, $white 0);
                }

                @include media-breakpoint-up(lg) {
                    background: linear-gradient($color4 80px, $white 0);
                    padding-top: 78px;
                }

                .custom-switch {
                    color: $color1;

                    label {
                        &:before {
                            border: 4px solid $color2!important;
                            background: $color2!important;
                        }
                    }
                }
            }
        }

        .subscriptions {
            .black-friday-banner {
                text-align: center;
                // padding-top: 15px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            &__wrapper {
                &:before {
                    border: none;
                }

                &:after {
                    content: "";
                    position: absolute;  
                    z-index: 2;
                    pointer-events: none;
                    border-top: 2px solid $color2;
                    border-bottom: 2px solid $color2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    @include media-breakpoint-up(md) {
                        width: calc(100% + 70px);
                    }

                    @include media-breakpoint-up(lg) {
                        width: calc(100% + 96px);
                    }
                }
            }

            &__container {
                background: none;

                #subs-slider {
                    align-items: center;
                }
            }

            &__item {
                width: unset;

                &:after {
                    border: none;
                }

                &:before {
                    border-color: $color1;
                }

                &--title {
                    @include fluid-type(360px, 1920px, 22px, 48px);
                }

                &--price {
                    color: $color1;
                }

                &.alternate {
                    background: transparent;
                    border: none;

                    &:before {
                        background: $white;
                    }
                }

                &--info {
                    list-style: disc;
                    text-align: left;
                    margin-bottom: 65px;

                    li {
                        margin-bottom: 0;
                        @include fluid-type(360px, 1920px, 14px, 18px);
                    }
                }

                &--button {                    
                    .register-acc {
                        font-weight: 400;
                        line-height: 1;
                        border-radius: 40px;
                        width: 100%;
                        padding: 14px 10px 13px;
                        height: unset;
                        max-width: 100%;
                        display: inline-flex;

                        @include media-breakpoint-up(xl) {
                            width: unset;
                            padding: 14px 60px 13px;
                        }
                    }
                }

                &--saving {
                    color: $color2;
                    @include fluid-type(360px, 1920px, 16px, 22px);
                    margin-bottom: 60px;
                }
            }
        }
    }

    .faq__wrapper {
        margin-top: 0;
        background: linear-gradient(0deg, $color4 calc(100% - 227px), $white 150px);

        @include media-breakpoint-up(md) {
            background: linear-gradient(0deg, $color4 calc(100% - 150px), $white 150px);
        }

        @include media-breakpoint-up(lg) {
            background: linear-gradient(90deg, $white 50%, $color4 50%);
        }

        .faq {
            &__text {
                color: $color12;

                &--question {
                    color: $color12;
                }

                ul {
                    counter-reset: section;

                    li {
                        &:before {
                            background: unset;
                            counter-increment: section;
                            content: counter(section)".";
                            top: 3px;
                        }
                    }
                }
            }
        }
    }

    .interestButton {
        transition: $transition;
        background: #e2e2e2;
        color: #788594;
        margin-top: 20px;
        font-size: 8px;

        @include media-breakpoint-up(md) {
            font-size: 10px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 11px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 14px;
        }

        &:hover {
            color: $black;
        }
    }

    .fui {
        &-submit {
            border: none;
            transition: $transition;
            background: $color2;

            &:hover {
                background: $color11;
            }
        }

        &-checkbox-label {
            a {
                color: $color2;
            }
        }
    }

    .thanksPage {
        text-align: center;
        padding-top: 140px;

        @include media-breakpoint-up(md) {
            padding-top: 160px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 200px;
        }

        &__title, &__text {
            color: $color11;
            font-weight: 300;
            line-height: 1.2;
        }

        &__title {
            font-size: 30px;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                font-size: 38px;
                margin-bottom: 20px;
            }
    
            @include media-breakpoint-up(lg) {
                font-size: 48px;
                margin-bottom: 20px;
            }
        }

        &__text {
            font-size: 18px;
            margin-bottom: 68px;

            @include media-breakpoint-up(md) {
                font-size: 22px;
                margin-bottom: 68px;
            }
    
            @include media-breakpoint-up(lg) {
                font-size: 26px;
                margin-bottom: 68px;
            }
        }

        &__img {

        }
    }

    .static {
        @include media-breakpoint-up(lg) {
            padding-top: 158px;
        }
    }

    .counterText {
        position: relative;
        text-align: center;

        &__wrapper {
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
                margin-bottom: 70px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 80px;
            }
    
        }

        &__counter {
            margin-bottom: 8px;

            &--icon {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 0;
                font-weight: 400;
                color: $color1;
                line-height: 1;
                @include fluid-type(360px, 1920px, 28px, 50px);
            }
        }

        &__text {
            p {
                margin-bottom: 0;
                font-weight: 300;
                color: $color1;
                line-height: 1.2;
                @include fluid-type(360px, 1920px, 18px, 24px);
            }
        }

        &__bg {
            position: absolute;
            top: calc(50% + 20px);
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            max-width: 75vw;

            @include media-breakpoint-up(md) {
                max-width: 100%;
            }
        }
    }
}